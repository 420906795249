/* eslint-disable no-unused-vars */
import { Box, Container, Link, Typography } from "@mui/material";
import logo from "./image/education.png";
import Play from "./image/Play.png";
import App from "./image/App.png";
import { useNavigate } from "react-router-dom";
import {ANDROID_URL, APPLE_URL, APP_NAME, APP_NAME_SMALL} from "../../App.js";

const Footer = () => {
  const navigator = useNavigate("");
  return (
    <Box
    
    >
    <Box
      sx={{
        backgroundColor: "#135050",
        color: "#fff",
        height: { xs: "0", md: "278px" },
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              marginInline: { xs: "0", lg: "6rem" },
              marginTop: "2rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "1.2rem",
              }}
            >
              <img src={logo} alt="logo" width="35px" height="35px" />
              <Typography
                variant="body1"
                sx={{
                  paddingLeft: "0.5rem",
                }}
              >
                {APP_NAME}
              </Typography>
            </Box>

            <Box>
              <Typography
                variant="body1"
                sx={{
                  paddingBottom: "0.4rem",
                }}
              >
                Download Our App From
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Box
                onClick={()=>window.open(`${ANDROID_URL}`, '_blank', 'noopener,noreferrer')}
                sx={{
                  cursor:"pointer",
                }}
                >
                  <img
                    src={Play}
                    alt=""
                    style={{
                      // paddingBottom: "2rem",
                    }}
                  />
                </Box>
                <Box
                onClick={()=>window.open(`${APPLE_URL}`, '_blank', 'noopener,noreferrer')}              
                  sx={{
                    cursor:"pointer",
                    marginLeft: { xs: "0", sm: "1rem" },
                  }}
                >
                  <img
                    src={App}
                    alt=""
                    style={{
                      paddingBottom: "2rem",
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body2">
                Copyright ©2024 {APP_NAME_SMALL} All Rights Reserved
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              marginTop: "2rem",
              marginInline: { xs: "0", lg: "6rem" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "start", md: "end" },
                justifyContent: "end",
              }}
            >
              <Typography variant="body1">Quick Links</Typography>

              <Box
                sx={{
                  paddingBottom: "1.5rem",
                 
                }}
              >
                <Link  sx={{color:"#fff"}} variant="body2" href="" underline="none"  onClick={()=>navigator("/home/notes")}>
                  Notes |{" "}
                </Link>
                <Link sx={{color:"#fff"}} variant="body2" href="" underline="none" onClick={()=>navigator("/home/flash-card")}>
                  Flash Card |{" "}
                </Link>
                <Link sx={{color:"#fff"}} variant="body2" href="" underline="none" onClick={()=>navigator("/home/quiz")}>
                  Quiz |{" "}
                </Link>
                {/* <Link sx={{color:"#fff"}} variant="body2" href="" underline="none" onClick={()=>navigator("/home/videos")}>
                  Video Course |{" "}
                </Link> */}
                <Link sx={{color:"#fff"}} variant="body2" href="" underline="none" onClick={()=>navigator("/home/form")}>
                  {" "}
                  Forms
                </Link>
              </Box>

              <Typography variant="body1">Other Links</Typography>
              <Box
               
              >
                
                <Link sx={{color:"#fff"}} variant="body2"  href="mailto:support@millennialsdesigner.com" underline="none">
                Support |{" "}
                </Link>
                <Link sx={{color:"#fff"}} variant="body2" href="https://mfocusreview.com/pages/terms-and-condition" target="_blank" underline="none">
                Terms & Conditions |{" "}
                </Link>
                <Link sx={{color:"#fff"}} variant="body2" target="_blank" href="https://mfocusreview.com/pages/privacy-policy" underline="none">
                Privacy Policy {" "}
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
    </Box>
  );
};

export default Footer;
