import { Box, Typography } from '@mui/material'
import React from 'react'
import video from "./image/Video 4.png"
import videolist from './image/list.png' 

const Video = () => {
  return (
    <Box
    sx={{
        display: "flex",
        flexDirection:{xs:"column",md:"row"},
        justifyContent:"center",
        alignItems:{xs:"center",md:"normal"},
        marginBlock:"3rem",
        marginInline:{xs:"0",md:"2rem"}
    }}
    >
    <Box
    sx={{
      marginRight: { xs: "0", md: "1rem" }
    }}
    >
        <Box
        sx={{
            marginInline:{xs:"1rem",md:"0"}
        }}
        >
            <img src={video} alt="" 
            width="100%"
          height="auto"
          style={{ margin: "0", padding: "0"}}
             />
            <Typography variant="body1"
            sx={{
                paddingBlock:"0.5rem"
            }}
            >#1 Med Surgical Bundle - Introduction</Typography>
            <Typography variant="body2"
            sx={{
                color:"#7D7D7D"
            }}
            >Donec sodales accumsan suscipit. Sed sagittis risus rutrum rutrum finibus. Donec porttitor dignissim est a facilisis. Pellentesque varius dictum urna, nec accumsan libero imperdiet vitae. Praesent non elit eget nisi finibus auctor. Vestibulum ac tincidunt nisi. Cras urna lorem, ornare non ultrices lobortis, vehicula eu elit. Sed finibus sem sit amet egestas condimentum.</Typography>
        </Box>
        </Box>
        
        <Box
        sx={{
          marginLeft: { xs: "0", md: "1rem" },
          marginTop: { xs: "1rem", md: "0" },
          
        }}

      >
        <Box sx={{
          
        }}>
        <VideoViwe value="#1 Med Surgical Bundle - Introduction" subtitle="05 : 20" />
        <VideoViwe value="#1 Med Surgical Bundle - Introduction" subtitle="05 : 20" />
        <VideoViwe value="#1 Med Surgical Bundle - Introduction" subtitle="05 : 20" />
        <VideoViwe value="#1 Med Surgical Bundle - Introduction" subtitle="05 : 20" />
        <VideoViwe value="#1 Med Surgical Bundle - Introduction" subtitle="05 : 20" />
        <VideoViwe value="#1 Med Surgical Bundle - Introduction" subtitle="05 : 20" />
        </Box>
        
      </Box>
        
    </Box>
  )
}

export default Video

function VideoViwe({ value, subtitle,image }) {
    return (
      <Box
        sx={{
          marginBottom: "0.5rem",
          cursor: "pointer",
         
          
        }}
      >
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#F7F7F7",
            width: { xs: "280px", sm: "450px" },
            paddingInline: "1.5rem",
            paddingBlock: "1rem",
            borderRadius: "9px",
           
            "&:hover": {
              backgroundColor: "#D9E9E9",
            },
          }}
        >
          <Box>
          <img src={videolist} alt="" width="100%"
          height="auto"
          style={{ margin: "0", padding: "0"}} />
          </Box>
          <Box
          sx={{
            paddingLeft:"0.7rem",
          }}
          >
            <Typography
              variant="body2"
              sx={{
                paddingBottom: "0.5rem",
                fontWeight:"550"
              }}
            >
              {value}
            </Typography>
            <Typography variant="body2">{subtitle}</Typography>
            </Box>
        </Box>
      </Box>
    );
  }
  