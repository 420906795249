import { Box } from "@mui/material";
import React from "react";
import CardPage from "../../Components/FlashCard/Card/CardPage";
import CardLink from "../../Components/FlashCard/Card/CardLink";

function FlashCardDetails() {
  return (
    <Box>
      <CardLink />
      <CardPage />
    </Box>
  );
}

export default FlashCardDetails;
