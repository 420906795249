/* eslint-disable no-unused-vars */
import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Paper } from "@mui/material";
import { useState } from "react";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db } from "../../../service/firebase_init";
import { useEffect } from "react";

let attempQC = 0;
let notAttempC = 0;
let correctAnsC = 0;
let inCorrectAnsC = 0;
let totalQC = 0;
let scorQC = 0;

const QuizDailogPageTwo = ({ id, qId, open, onClose }) => {
  const [note, setNote] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [subNote, setSubNote] = useState([]);
  const [selected, setSelected] = useState(0);
  const [userQ, setUserQ] = useState({});

  const getData = async () => {
    const data = query(doc(db, "notes/" + id + "/subChapters/", qId));
    const d = await getDoc(data);
    if (d.data()) {
      setNote(d.data());
    }

    // console.log(d.data());
  };

  const getSubNote = async () => {
    const data = collection(
      db,
      "notes/" + id + "/subChapters/" + qId + "/quiz"
    );
    const d = await getDocs(data);

    const dU = doc(
      db,
      "users/" + localStorage.getItem("uId") + "/quizStatus/" + qId
    );

    const gU = await getDoc(dU);
    setUserQ(gU.data());

    // console.log("dataaa",d.docs.length);
    if (d?.docs?.length) {
      const t = [];
      for (const i of d.docs) {
        // console.log("dataaa",i.data());
        t.push(i.data());
      }

      getQuizStatus(t);
    }
    // console.log("length",d?.docs?.length);
  };

  const getQuizStatus = async (t) => {
    attempQC = 0;
    notAttempC = t.length;
    correctAnsC = 0;
    inCorrectAnsC = 0;
    totalQC = t.length;

    const d = doc(
      db,
      "users/" + localStorage.getItem("uId") + "/quizStatus/" + qId
    );

    const dd = await getDoc(d);
    if (dd.data()) {
      scorQC = dd.data().score;
      //console.log(Object.keys(dd.data().answersList));
      const keys = Object.keys(dd.data().answersList);

      if (keys.length) {
        attempQC = keys.length;
        notAttempC = t.length - attempQC;
        for (const [index, val] of t.entries()) {
          if (keys.includes(val.id)) {
            //const ind = keys.indexOf(val.id);
            // console.log(dd.data().answersList[val.id]);
            t[index].userAns = dd.data().answersList[val.id];
          }
        }

        for (const [index, checkA] of t.entries()) {
          if (t[index].userAns !== null) {
            const dataA = doc(
              db,
              "notes/" + id + "/subChapters/" + qId + "/quiz/" + checkA.id
            );
            const dA = await getDoc(dataA);

            if (dA.data() && dA.data().correctAns === t[index].userAns) {
              t[index].ansStatus = 1;
              correctAnsC += 1;
            } else {
              t[index].ansStatus = -1;
              inCorrectAnsC += 1;
            }
          } else {
            t[index].ansStatus = 0;
          }
        }
        setSubNote(t);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
    getSubNote();
  }, []);

  return (
    <Dialog open={open} onClose={() => onClose(null)}>
      {isLoading ? (
        <DialogContent
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="80vh"
          width="500px"
        >
          <CircularProgress />
        </DialogContent>
      ) : (
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <NestedList
              subNote={subNote}
              changeIndex={(index) => onClose(index)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              mb: "10px",
            }}
          >
            <Typography
              sx={{
                backgroundColor: "#c0c0c079",
                padding: "8px",
                margin: "5px",
                borderRadius: "10px",
              }}
            >
              Attemp - {attempQC}
            </Typography>
            <Typography
              sx={{
                backgroundColor: "rgba(123, 130, 124, 0.2)",
                padding: "8px",
                margin: "5px",
                borderRadius: "10px",
              }}
            >
              Not Attemp - {notAttempC}
            </Typography>
            <Typography
              sx={{
                backgroundColor: "rgba(28, 187, 44, 0.2)",
                padding: "8px",
                margin: "5px",
                borderRadius: "10px",
              }}
            >
              Correct Answer - {correctAnsC}
            </Typography>
            <Typography
              sx={{
                backgroundColor: "rgba(187, 28, 86, 0.2)",
                padding: "8px",
                margin: "5px",
                borderRadius: "10px",
              }}
            >
              Incorrect Correct Answer - {inCorrectAnsC}
            </Typography>
          </Box>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          variant="text"
          sx={{
            alignSelf: "flex-end",
          }}
          onClick={onClose}
        >
          <Typography> Close</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuizDailogPageTwo;

function NestedList({ subNote, changeIndex }) {
  return (
    <List
      sx={{
        width: "100%",
        maxWidth: "100%",
        borderRadius: "13px",
        // backgroundColor: "#F4F4F4",
        padding: "1rem",
      }}
    >
      <ListItemButton
        sx={{
          backgroundColor: "rgba(205, 205, 205, 0.2);",
          borderRadius: "13px",
        }}
      >
        <ListItemText primary="All Question" />
      </ListItemButton>

      <List
        sx={{
          marginTop: "1rem",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {subNote.map((val, index) => {
          return (
            <ListItemButton
              onClick={() => changeIndex(index)}
              key={index}
              sx={{
                backgroundColor:
                  val.ansStatus === 1
                    ? "rgba(28, 187, 44, 0.2);"
                    : val.ansStatus === -1
                    ? "rgba(187, 28, 86, 0.2);"
                    : "rgba(123, 130, 124, 0.2);",
                borderRadius: "13px",
                margin: "0.5rem",
              }}
            >
              <ListItemText primary={`Question ${index + 1}`} />
            </ListItemButton>
          );
        })}
      </List>
    </List>
  );
}
function QuizViwe({ value, subtitle }) {
  return (
    <Box
      sx={{
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "center",

        backgroundColor: "rgba(21, 66, 63, 0.02);",
        borderRadius: "27px",
        border: "1px solid rgba(19, 80, 80, 0.3)",
        marginBlock: "0.5rem",
        paddingBlock: "0.5rem",
        paddingInline: "1rem",
        color: "#135050",
        textTransform: "none",
      }}
    >
      <Box>
        <Typography
          variant="body2"
          sx={{
            textTransform: "none",
            fontWeight: "300",
          }}
        >
          {value}
        </Typography>
        {/* <img src={image} 
            
            alt="" /> */}
      </Box>
      <Box>
        <Typography variant="body2" sx={{ fontWeight: "300" }}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}
