import { createTheme } from "@mui/material/styles";

export const theme = (mode = "ligh") => {
  return createTheme({
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      body1: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "0.8rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "1rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "1rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "1rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "1.1rem",
        },
        // backgroundColor:colors.grey["700"]
      },
      body2: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "0.7rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "0.9rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "0.9rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "0.9rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "0.9rem",
        },
        // color:colors.grey["700"]
      },
        h4: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "0.9rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "0.9rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "1rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "1rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "1.8rem",
        },
        // color:colors.grey["700"]
      },
      h3: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "2rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "2rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "2rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "2rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "2rem",
        },
        // color:colors.grey["700"]
      },
    },

    palette: {
      mode: mode,

      primary: {
        main: "#15423F",
      },
      secondary: {
        main: "#15423F",
      },
      tertiary:{
        main:"#FF0000"
      },

      default: {
        background: "#fff",
        paper: "#F2F2F2",
      },
    },
  });
};
