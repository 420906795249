const config = {
    'app.nclex.focuslearninghub.com': {
        APP_NAME: process.env.REACT_APP_NURSING_APP_NAME,
        APP_NAME_SMALL: process.env.REACT_APP_NURSING_APP_NAME_SMALL,
        ANDROID_URL: process.env.REACT_APP_NURSING_ANDROID_URL,
        APPLE_URL: process.env.REACT_APP_NURSING_APPLE_URL,
        APP_ID: process.env.REACT_APP_NURSING_AUTH_APP_ID,
    },
    'app.med.focuslearninghub.com': {
        APP_NAME: process.env.REACT_APP_MED_APP_NAME,
        APP_NAME_SMALL: process.env.REACT_APP_MED_APP_NAME_SMALL,
        ANDROID_URL: process.env.REACT_APP_MED_ANDROID_URL,
        APPLE_URL: process.env.REACT_APP_MED_APPLE_URL,
        APP_ID: process.env.REACT_APP_MED_AUTH_APP_ID,
    },
    'app.vet.focuslearninghub.com': {
        APP_NAME: process.env.REACT_APP_VET_APP_NAME,
        APP_NAME_SMALL: process.env.REACT_APP_VET_APP_NAME_SMALL,
        ANDROID_URL: process.env.REACT_APP_VET_ANDROID_URL,
        APPLE_URL: process.env.REACT_APP_VET_APPLE_URL,
        APP_ID: process.env.REACT_APP_VET_AUTH_APP_ID,
    },
    'app.pmp.focuslearninghub.com': {
        APP_NAME: process.env.REACT_APP_PMP_APP_NAME,
        APP_NAME_SMALL: process.env.REACT_APP_PMP_APP_NAME_SMALL,
        ANDROID_URL: process.env.REACT_APP_PMP_ANDROID_URL,
        APPLE_URL: process.env.REACT_APP_PMP_APPLE_URL,
        APP_ID: process.env.REACT_APP_PMP_AUTH_APP_ID,
    },
    'app.gre.focuslearninghub.com': {
        APP_NAME: process.env.REACT_APP_GRE_APP_NAME,
        APP_NAME_SMALL: process.env.REACT_APP_GRE_APP_NAME_SMALL,
        ANDROID_URL: process.env.REACT_APP_GRE_ANDROID_URL,
        APPLE_URL: process.env.REACT_APP_GRE_APPLE_URL,
        APP_ID: process.env.REACT_APP_GRE_AUTH_APP_ID,
    },
    'app.re.focuslearninghub.com': {
        APP_NAME: process.env.REACT_APP_RE_APP_NAME,
        APP_NAME_SMALL: process.env.REACT_APP_RE_APP_NAME_SMALL,
        ANDROID_URL: process.env.REACT_APP_RE_ANDROID_URL,
        APPLE_URL: process.env.REACT_APP_RE_APPLE_URL,
        APP_ID: process.env.REACT_APP_RE_AUTH_APP_ID,
    },
    'app.toefl.focuslearninghub.com': {
        APP_NAME: process.env.REACT_APP_TOEFL_APP_NAME,
        APP_NAME_SMALL: process.env.REACT_APP_TOEFL_APP_NAME_SMALL,
        ANDROID_URL: process.env.REACT_APP_TOEFL_ANDROID_URL,
        APPLE_URL: process.env.REACT_APP_TOEFL_APPLE_URL,
        APP_ID: process.env.REACT_APP_TOEFL_AUTH_APP_ID,
    },
    'app.ielts.focuslearninghub.com': {
        APP_NAME: process.env.REACT_APP_IELTS_APP_NAME,
        APP_NAME_SMALL: process.env.REACT_APP_IELTS_APP_NAME_SMALL,
        ANDROID_URL: process.env.REACT_APP_IELTS_ANDROID_URL,
        APPLE_URL: process.env.REACT_APP_IELTS_APPLE_URL,
        APP_ID: process.env.REACT_APP_IELTS_AUTH_APP_ID,
    },
    'app.dental.focuslearninghub.com': {
        APP_NAME: process.env.REACT_APP_DENTAL_APP_NAME,
        APP_NAME_SMALL: process.env.REACT_APP_DENTAL_APP_NAME_SMALL,
        ANDROID_URL: process.env.REACT_APP_DENTAL_ANDROID_URL,
        APPLE_URL: process.env.REACT_APP_DENTAL_APPLE_URL,
        APP_ID: process.env.REACT_APP_DENTAL_AUTH_APP_ID,
    },
    'app.nclexpn.focuslearninghub.com': {
        APP_NAME: process.env.REACT_APP_NCLEXPN_APP_NAME,
        APP_NAME_SMALL: process.env.REACT_APP_NCLEXPN_APP_NAME_SMALL,
        ANDROID_URL: process.env.REACT_APP_NCLEXPN_ANDROID_URL,
        APPLE_URL: process.env.REACT_APP_NCLEXPN_APPLE_URL,
        APP_ID: process.env.REACT_APP_NCLEXPN_AUTH_APP_ID,
    },
    'app.asvab.focuslearninghub.com': {
        APP_NAME: process.env.REACT_APP_ASVAB_APP_NAME,
        APP_NAME_SMALL: process.env.REACT_APP_ASVAB_APP_NAME_SMALL,
        ANDROID_URL: process.env.REACT_APP_ASVAB_ANDROID_URL,
        APPLE_URL: process.env.REACT_APP_ASVAB_APPLE_URL,
        APP_ID: process.env.REACT_APP_ASVAB_AUTH_APP_ID,
    },
    'app.atiteas.focuslearninghub.com': {
        APP_NAME: process.env.REACT_APP_ATITEAS_APP_NAME,
        APP_NAME_SMALL: process.env.REACT_APP_ATITEAS_APP_NAME_SMALL,
        ANDROID_URL: process.env.REACT_APP_ATITEAS_ANDROID_URL,
        APPLE_URL: process.env.REACT_APP_ATITEAS_APPLE_URL,
        APP_ID: process.env.REACT_APP_ATITEAS_AUTH_APP_ID,
    },
    'app.sat.focuslearninghub.com': {
        APP_NAME: process.env.REACT_APP_SAT_APP_NAME,
        APP_NAME_SMALL: process.env.REACT_APP_SAT_APP_NAME_SMALL,
        ANDROID_URL: process.env.REACT_APP_SAT_ANDROID_URL,
        APPLE_URL: process.env.REACT_APP_SAT_APPLE_URL,
        APP_ID: process.env.REACT_APP_SAT_AUTH_APP_ID,
    },
    'app.cna.focuslearninghub.com': {
        APP_NAME: process.env.REACT_APP_CNA_APP_NAME,
        APP_NAME_SMALL: process.env.REACT_APP_CNA_APP_NAME_SMALL,
        ANDROID_URL: process.env.REACT_APP_CNA_ANDROID_URL,
        APPLE_URL: process.env.REACT_APP_CNA_APPLE_URL,
        APP_ID: process.env.REACT_APP_CNA_AUTH_APP_ID,
    },
    'localhost': {
        APP_NAME: process.env.REACT_APP_DENTAL_APP_NAME,
        APP_NAME_SMALL: process.env.REACT_APP_DENTAL_APP_NAME_SMALL,
        ANDROID_URL: process.env.REACT_APP_DENTAL_ANDROID_URL,
        APPLE_URL: process.env.REACT_APP_DENTAL_APPLE_URL,
        APP_ID: process.env.REACT_APP_DENTAL_AUTH_APP_ID,
    },
};

module.exports = Object.freeze(config);