import { Close, Send } from "@mui/icons-material";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, Timestamp } from "firebase/firestore";
import TimeAgo from "javascript-time-ago";
import React, { useEffect, useState } from "react";
import { db } from "../../service/firebase_init";

function CommentDailog({ id, open, handleClose }) {
//   console.log(id);

  const [row, setRow] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSender, setIsLoadingSender] = useState(false);
  const [comment,setComment]  = useState("");

  const getData = async () => {
    setIsLoading(true);
    const d = collection(db, "discussion/" + id + "/comment");

    const dd = await getDocs(d);

    const t = [];

    if (dd.docs.length) {
      for (const [index, val] of dd.docs.entries()) {
        // console.log("datttt = ", val.data());
        const ud = query(doc(db, "users/" + val.data()["answeredBy"])) ;
        const udd = await getDoc(ud);

        t.push({ user: udd.data(), comment: val.data() });
      }

     // console.log(t.length);
      setRow([...t]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const sendData = async () =>{
    setIsLoadingSender(true);
    const i = Date.now();
    const d = doc(db,"discussion/"+id+"/comment/"+i);

    await setDoc(d,{
        answer:comment,
        answerId:i,
        answeredBy:localStorage.getItem("uId"),
        comment:0,
        createdAt:Timestamp.now(),
        image:"",
        queationId:id
    });
    setComment("");
    setIsLoadingSender(false);
    getData();
    

  }

  return (
    <Dialog
    fullWidth
    open={open} onClose={() => handleClose(0)}>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 3,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <DialogContent>
          <Box display="flex" justifyContent="space-between">
            <DialogTitle>Comment</DialogTitle>
            <IconButton onClick={() => handleClose(0)}>
              <Close />
            </IconButton>
          </Box>

          <Box
          height={`${ row.length === 0  ? "0px" : "300px" }`}
          width="100%"

          sx={{
            overflowY:"auto"
          }}
          >

          {row.map((v, index) => {
            return <Contentview key={index} val={v} />;
          })}
            </Box> 

          {
            row.length === 0 ? <Typography sx={{ml:3}}>No record found</Typography> : null
          }
          <Box
          sx={{
            mx:1,
            mt:2,
            display:"flex"
          }}
          >
            <TextField value={comment} onChange={(e)=>setComment(e.target.value)} multiline rows={3}  label="Answer" size="small" fullWidth/>
          {
            isLoadingSender ? <CircularProgress/> :<IconButton onClick={()=>sendData()}><Send/></IconButton>
          }  
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
}

export default CommentDailog;

function Contentview({ val }) {
//   console.log(val);
  const timeAgo = new TimeAgo("en-US");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        borderRadius: "5px",
        marginInline: { xs: "0rem", sm: "3rem", md: "0", lg: "3rem" },
        marginBlock: "1rem",
        backgroundColor: "#fff",
        // px: 2,
      }}
    >
      <Paper elevation={3}>
        <Box
          sx={{
            paddingInline: { xs: "1rem", md: "1.5rem" },
            paddingBlock: "1rem",
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={val.user.profilePhoto}
                alt=""
                width="35px"
                height="35px"
              />
              <Typography
                variant="body1"
                sx={{
                  pl: 1,
                }}
              >
                {val.user.firstName}
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="body2"
            sx={{
              pt: 1,
            }}
          >
            {val.comment.answer}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pt: 1,
            }}
          >
            <Typography variant="body2">
              {timeAgo.format(new Date(val.comment.createdAt.toDate()))}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
