/* eslint-disable no-unused-vars */
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import plan from "./image/Plan.png";
import frame from "./image/frame.png";
import Rectangle2 from "./image/Rectangle 2.png";
import { useNavigate, useParams } from "react-router-dom";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc, Timestamp, where } from "firebase/firestore";
import { CircularProgress } from "@mui/material";
import { auth, db } from "../../../service/firebase_init";
import { useState } from "react";
import { useEffect } from "react";
import PlannerDailog from "../../Dailog/PlannerDailog";
import { dateFormatDDMMMYYYY } from "../../../service/date";
import { isUserPremium } from "../../../App";
import { Lock } from "@mui/icons-material";
import Plan from "../../../Pages/Plan";
import axios from "axios";
import qs from "qs";
import { stripe_sk, success_url_var, cancel_url_var } from "../../../variables";


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: "90%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D0D0D0",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#135050" : "#D0D0D0    ",
  },
}));


const QuizPageone = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState(null);
  const [subNote, setSubNote] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [completed, setCompleted] = useState(-1);
  const [planner, setPlanner] = useState(null);

  const getPlannerData = async () => {
    const check = query(collection(db, "users/" + localStorage.getItem("uId") + "/planner"), where("chapterId", "==", id), where("userId", "==", localStorage.getItem("uId")));
    const get = await getDocs(check);

    if (get.docs.length) {
      setPlanner(get.docs[0].data());
      const quizCheck = query(collection(db, "users/" + localStorage.getItem("uId") + "/quizStatus"), where("notesId", "==", id), where("userId", "==", localStorage.getItem("uId")));
      const gets = await getDocs(quizCheck);
      setCompleted(gets.docs.length);
    }
  }


  const handleClose = async (v = 0, dateTime) => {
    // console.log(v);
    // console.log(dateTime);
    setOpen(false);
    if (v) {
      const data = doc(db, "users", localStorage.getItem("uId"), "planner", id);

      const check = query(collection(db, "users/" + localStorage.getItem("uId") + "/planner"), where("chapterId", "==", id), where("userId", "==", localStorage.getItem("uId")));
      const quizCheck = query(collection(db, "users/" + localStorage.getItem("uId") + "/quizStatus"), where("notesId", "==", id), where("userId", "==", localStorage.getItem("uId")));

      const get = await getDocs(check);
      if (get.docs.length) {
        for (let f of get.docs) {

          try {
            await deleteDoc(f.ref);
          } catch (e) {
            // console.log("error")
            // console.log(e)
          }
        }
      }

      const getQuiz = await getDocs(quizCheck);
      if (getQuiz.docs.length) {
        for (let f of getQuiz.docs) {

          try {
            await deleteDoc(f.ref);
          } catch (e) {
            // console.log("error")
            // console.log(e)
          }
        }
      }

      await setDoc(data, {
        chapterId: id,
        createdAt: Timestamp.now(),
        dueDate: Timestamp.fromDate(dateTime),
        type: 1,
        userId: localStorage.getItem("uId")
      });

      getPlannerData();
      // console.log(localStorage.getItem("uId"));

    }

  }

  const getData = async () => {
    setIsLoading(true);
    const data = query(doc(db, "notes", id));
    const d = await getDoc(data);
    if (d.data()) {
      setNote(d.data());
    }
    setIsLoading(false);
    // console.log(d.data());
  };

  const getSubNote = async () => {
    const data = query(collection(db, "notes/" + id + "/subChapters"), orderBy("index", "asc"));
    const d = await getDocs(data);
    if (d?.docs?.length) {
      const t = [];
      for (const i of d.docs) {
        // console.log("index",i.data().index);
        // console.log(isUserPremium,"isUserPremium");
        // if(i.isPremium){
        //   if(isUserPremium){
        //     t.push(i.data());
        //   }
        //   continue;
        // }
        t.push(i.data());
      }
      setSubNote(t);
    }
    // console.log("length",d?.docs?.length);
  };

  useEffect(() => {
    getData();
    getSubNote();
    getPlannerData();
  }, []);

  return isLoading ? (
    <Box display="flex" justifyContent="center" height="50vh">
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Box
        sx={{
          display: "flex",
          marginBlock: "3rem",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "center", md: "normal" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: { xs: "80%", sm: "60%", md: "35%", lg: "25%" },
            flexDirection: "column",
            marginRight: { xs: "0", md: "2rem" },
            backgroundColor: "rgba(21, 66, 63, 0.08);",
            borderRadius: "10px",
            paddingInline: "2rem",
            paddingBlock: "1rem",
          }}
        >
          <img
            src={note.poster}
            alt=""
            width="100%"
            height="auto"
            style={{ margin: "0", padding: "0" }}
          />
          <Typography
            variant="h4"
            sx={{
              fontWeight: "500",
              paddingBlock: "0.8rem",
            }}
          >
            {note.name}
          </Typography>
          {
            completed !== -1 ?
              <Box>
                <BorderLinearProgress variant="determinate" value={completed === 0 ? 0 : (completed / subNote.length) * 100} />
              </Box>
              : null
          }

          {
            completed !== -1 ?
              <Box
                sx={{
                  display: "flex",
                  paddingTop: "0.5rem",
                  color: "#717171",
                }}
              >
                <Typography variant="body2">{subNote.length} Quiz Set / </Typography>
                <Typography variant="body2" sx={{ ml: 1 }}>{completed} Completed</Typography>
              </Box>
              : null
          }

          <Box
            onClick={planner === null ? () => setOpen(true) : null}
            sx={{
              border: "1px solid #E0E0E0",
              width: { xs: "85%", sm: "60%", md: "70%", lg: "70%" },
              padding: "8px",
              borderRadius: "6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "0.8rem",
              backgroundColor: "#fff",
              cursor: "pointer"
            }}
          >
            {
              planner === null ? <img src={plan} alt="" /> : null
            }
            <Typography
              variant="body2"
              sx={{
                pl: 1,
              }}
            >
              {planner === null ? "Create Study Plan" : `Due date - ${dateFormatDDMMMYYYY(planner.dueDate.toDate())}`}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            marginLeft: { xs: "0", md: "2rem" },
            marginTop: { xs: "1rem", md: "0" },
            height: "80vh",
            overflowY: "auto",
          }}
        >
          {subNote.map((v, i) => {
            return v.quizCount === 0 ? null : (
              <CardPageViwe
                isPremium={v.isPremium}
                key={v.id}
                id={v.id}
                value={i + 1 + ". " + v.name}
                subtitle={`${v.quizCount} Questions`}
              />
            );
          })}
        </Box>
      </Box>
      <PlannerDailog open={open} handleClose={handleClose} />
    </>
  );
};

export default QuizPageone;

function CardPageViwe({ value, subtitle, id, isPremium }) {
  const [open, setOpen] = useState(false);

  async function handleCheckout(amount, packageV) {

    // console.log("isUserPremium1",isUserPremium);
    if (isUserPremium) {
      return;
    } else {

      let data = qs.stringify({
        cancel_url: cancel_url_var,
        success_url: success_url_var,
        "line_items[0][price_data][currency]": "usd",
        "line_items[0][price_data][product_data][name]": "Subscription",
        "line_items[0][price_data][unit_amount]": amount * 100,
        "line_items[0][quantity]": "1",
        mode: "payment",
        "payment_method_types[0]": "card",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.stripe.com//v1/checkout/sessions",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + stripe_sk,
        },
        data: data,
      };

      axios
        .request(config)
        .then(async (response) => {
          // console.log( auth.currentUser.uid , "UID")
          const data = doc(db, "users", auth.currentUser.uid);
          await setDoc(
            data,
            {
              isSubscribe: false,
              subscribetionpackge: packageV,
              payment_intent: response.data.payment_intent,
            },
            { merge: true }
          );
          window.open(response.data.url, "_self");
        })
        .catch((error) => {
          console.log(error);
        });
    }

  }


  const navigator = useNavigate();


  return (

    <>
      <Box
        sx={{
          marginBottom: "0.8rem",
          cursor: "pointer",
        }}
        onClick={() => {

          if (isPremium && isUserPremium) {

            navigator(id)
            // window.open(link);

          } else {
            if (isPremium) {
              //  
              setOpen(true);
            } else {

              navigator(id)
              // window.open(link);

            }
          }
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F7F7F7",
            width: { xs: "280px", sm: "450px" },
            display: "flex",
            justifyContent: "space-between",
            paddingInline: "2rem",
            paddingBlock: "1rem",
            borderRadius: "9px",
            "&:hover": {
              backgroundColor: "#D2E3E3",
            },
          }}
        >
          <Box>
            <Typography
              variant="body1"
              sx={{
                paddingBottom: "0.1rem",
              }}
            >
              {value}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#565656",
              }}
            >
              {subtitle}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {
              isPremium && !isUserPremium ? <Lock sx={{ color: "#5a5555" }} /> : <img src={frame} alt="" style={{}} />
            }

          </Box>
        </Box>


      </Box>
      {open && (
        <Plan
          handleOnClose={() => {

            setOpen(false);
          }}
          open={open}
          onCall={(v, packageV) => {
            handleCheckout(v, packageV);
          }}
        />
      )}
    </>
  );
}
