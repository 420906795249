import { Box } from '@mui/material'
import React from 'react'
import FlashCardLink from "../../Components/FlashCard/FlashCardLink";
import FlashCard from "../../Components/FlashCard/FlashCard";
import ButtonMore from "../../Components/Home/ButtonMore";

function FlashCardPage() {
  return (
    <Box>
         <FlashCardLink/>
           <FlashCard/>
           {/* <ButtonMore/> */}
    </Box>
  )
}

export default FlashCardPage