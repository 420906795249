import { Box } from "@mui/system";
import React from "react";
import Forum from "../../Components/Home/Discussion Forum/Forum";
import ForumLink from "../../Components/Home/Discussion Forum/ForumLink";

function FormLink() {
  return (
    <Box>
     
      <ForumLink />
      <Forum />
    </Box>
  );
}

export default FormLink;
