/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { Link, Typography } from "@mui/material";
import plan from "./image/Plan.png";
import { useNavigate } from "react-router-dom";
import { collection, deleteDoc, doc, getDocs, orderBy, query, setDoc, Timestamp, where } from "firebase/firestore";
import { db, auth } from "../../service/firebase_init";
import PlannerDailog from "../Dailog/PlannerDailog";
import { dateFormatDDMMMYYYY } from "../../service/date";
import { onAuthStateChanged } from "firebase/auth";

const Notes = () => {

  const navigator = useNavigate();
  const [uId, setUId] = useState(null);

  const [notes, setNotes] = useState([]);

  const [open, setOpen] = useState(false);
  let [selectedId, setSelectedId] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const getNotes = async () => {
    setIsLoading(true);
    const data = query(collection(db, "notes"),where("hideNote","==",true), orderBy("index", "asc"));

    const d = await getDocs(data);

    //  console.log(d?.docs?.length);

    if (d?.docs?.length) {
      const t = [];
      for (const [index, i] of (d.docs.length ? d.docs : []).entries()) {
        // console.log(i.data().hasData)
        if(!i.data().hasData){
          continue;
        }
        t.push(i.data());

        const check = query(
          collection(
            db,
            "users/" + localStorage.getItem("uId") + "/planner"
          ),
          where("chapterId", "==", i.id),
          where("userId", "==", localStorage.getItem("uId"))
        );
        const get = await getDocs(check);

        const datas = collection(db, "notes/" + i.id + "/subChapters");
        const d = await getDocs(datas);

        t[t.length-1].notes = d.docs.length;

        if (get.docs.length) {
          const cardCheck = query(
            collection(
              db,
              "users/" + localStorage.getItem("uId") + "/noteStatus"
            ),
            where("notesId", "==", i.id),
            where("userId", "==", localStorage.getItem("uId"))
          );
          const gets = await getDocs(cardCheck);
          t[t.length-1].planner = get.docs[0].data();
          t[t.length-1].completed = gets.docs.length;
        } else {
          t[t.length-1].completed = -1;
        }
      }
      setNotes(t);
    }
    setIsLoading(false);
  };

  const handleClose = async (v = 0, dateTime) => {
    // console.log(v);
    // console.log(dateTime);
    setOpen(false);
    if (v) {
      const data = doc(
        db,
        "users",
        localStorage.getItem("uId"),
        "planner",
        selectedId
      );

      const check = query(
        collection(
          db,
          "users/" + localStorage.getItem("uId") + "/planner"
        ),
        where("chapterId", "==", selectedId),
        where("userId", "==", localStorage.getItem("uId"))
      );
      const quizCheck = query(
        collection(
          db,
          "users/" + localStorage.getItem("uId") + "/noteStatus"
        ),
        where("notesId", "==", selectedId),
        where("userId", "==", localStorage.getItem("uId"))
      );

      const get = await getDocs(check);
      if (get.docs.length) {
        for (let f of get.docs) {
          try {
            await deleteDoc(f.ref);
          } catch (e) {
            // console.log("error")
            // console.log(e)
          }
        }
      }

      const getQuiz = await getDocs(quizCheck);
      if (getQuiz.docs.length) {
        for (let f of getQuiz.docs) {
          try {
            await deleteDoc(f.ref);
          } catch (e) {
            // console.log("error")
            // console.log(e)
          }
        }
      }

      await setDoc(data, {
        chapterId: selectedId,
        createdAt: Timestamp.now(),
        dueDate: Timestamp.fromDate(dateTime),
        type: 0,
        userId: localStorage.getItem("uId"),
      });

      getNotes();
      // console.log(localStorage.getItem("uId"));
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {        
        const uid = user.uid;
        setUId(uid);    
        getNotes();          
      } else {
        // User is signed out
        localStorage.removeItem("uId");
        setUId(null);
        navigator("/web");
      }
    });
  }, []);

  return ( isLoading ? 
    <Box
        height="80vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    :
    <Box>
      <Box
        sx={{
          backgroundColor: "#F2F2F2",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F2F2F2",
            display: "flex",
            alignItems: "center",
            height: "3.1rem",

            pl: { xs: "0.5rem", md: "4rem" },
          }}
        >
          <Link
            href=""
            variant="body2"
            sx={{
              color: "#009D9D",
            }}
            onClick={() => navigator("/home")}
          >
            Home
          </Link>
          <Link
          
            underline="none"
            sx={{
              color: "#000",
              px: "0.4rem",
            }}
          >
            /
          </Link>

          <Link
            href=""
            variant="body2"
            sx={{
              color: "#000",
            }}
          >
            Notes
          </Link>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pr: { xs: "0.5rem", md: "4rem" },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              display: { xs: "none", sm: "flex" },
            }}
          >
            Sort By
          </Typography>
          <Box>
            <MenuItem
              sx={{
                background: { xs: "none", md: "#fff" },
                ml: "0.8rem",
                borderRadius: "6px",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <em>Latest</em>
              </Typography>
              <img
                src={Arrow}
                alt=""
                style={{
                  paddingLeft: "0.5rem",
                }}
              />
            </MenuItem>
          </Box>
        </Box> */}
      </Box>

      <Box>
        <Grid container spacing={0}>

          {notes.map((v) => {
            return (
              <Grid
                key={v.id}
                item
                xs={6}
                sm={4}
                lg={3}
                component={"div"}
                
              >
                <OurNotesView
                  images={v.poster}
                  value={v.name}
                  description={v.description}
                  v={v}
                  setSelectedId={setSelectedId}
                  setOpen={setOpen}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <PlannerDailog open={open} handleClose={handleClose} />
    </Box>
  );
};

export default Notes;

function OurNotesView({ images, value, description,v, setSelectedId, setOpen }) {
 
  const navigator = useNavigate();

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        alignItems:"center",
        marginBlock: "2rem",
        marginInline: { xs: "1rem", md: "2rem", lg: "3rem" },
      }}
    >
      
      <Box
      onClick={() => navigator(v.id)}
      >
      <img
        src={images}
        alt=""
        width="100%"
        height="auto"
        style={{
          margin: "0",
          padding: "0",
          boxShadow: "0px 0px 1px 0px rgb(0 0 0 / 50%)",
          borderRadius: "15px",
        }}
      />
      </Box>
      <Typography
        variant="body1"
        sx={{
         whiteSpace:"nowrap",
         overflow:"hidden",
         textOverflow:"ellipsis",
          lineBreak:"strict",
          fontWeight: "700",
          pt: "0.5rem",
        }}
      >
        {value}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontWeight: "200",
          color: "#A5A5A5",
          pt: "0.3rem",
        }}
      >
        {description}
      </Typography>
      {v.completed === -1 ? null : (
          <Typography
            variant="body2"
            sx={{
              marginTop: "0.8rem",
              color: "#717171",
            }}
          >
            {v.notes} Notes Set / {v.completed} Completed
          </Typography>
        )}
      <Box
      onClick={()=>{
        if(v.planner === null || v.planner === undefined){
          setSelectedId(v.id);
          setOpen(true);
        }
        
      }}
        sx={{
          border: "1px solid #E0E0E0",
          width: "auto",
          // width: { xs: "100%", sm: "80%" },
          padding: "8px",
          borderRadius: "6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "0.8rem",
          cursor:"pointer"
        }}
      >
        {
          (v.planner === null || v.planner === undefined) ? <img src={plan} alt="" /> :null
        }
        
        <Typography
          variant="body2"
          sx={{
            pl: 1,
          }}
        >
          {(v.planner === null || v.planner === undefined) ? "Create Study Plan" : `Due date - ${dateFormatDDMMMYYYY(v.planner.dueDate.toDate())}`}  
        </Typography>
      </Box>
    </Grid>
  );
}
