import { Box } from '@mui/material'
import React from 'react'
import Planner from '../../Components/StudyPlanner/Planner'
import PlannerLink from '../../Components/StudyPlanner/PlannerLink'

const PlannerPage = () => {
  return (
    <Box>
    <PlannerLink/>
    <Planner/>
    </Box>
  )
}

export default PlannerPage