import {
  Box,
  Typography,
  Grid,
  Button,
  Paper,
  CircularProgress,
  IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ModeIcon from "@mui/icons-material/Mode";
import TimeAgo from "javascript-time-ago";

import { db } from "../../../service/firebase_init";
import {
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import DiscussionDailog from "../../Dailog/DiscussionDailog";
import { Delete, Edit } from "@mui/icons-material";
import ConfirmationDailog from "../../Dailog/ConfirmationDailog";
import CommentDailog from "../../Dailog/CommentDailog";
import ReportData from "../../Dailog/ReportData";

const Forum = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openC, setOpenC] = useState(false);
  const [selectId, setSelectId] = useState(0);
  const [filter, setFilter] = useState("");
  const [row, setRow] = useState(null);
  const [commentId, setCommentId] = useState(0);
  const [openCm, setOpenCm] = useState(false);

  const handleClose = (v) => {
    if (v) {
      getData();
    }
    setRow(null);
    setOpen(false);
  };

  const handleCloseCM = (v) => {
    if (v) {
      getData();
    }
    setOpenCm(false);
  };

  const handleOnCloseC = async (v) => {
    setOpenC(false);
    if (v) {
      const d = doc(db, "discussion/" + selectId);
      const dd = await getDoc(d);
      deleteDoc(dd.ref);
      getData();
    }
  };

  const getData = async () => {
    setIsLoading(true);
    const d = collection(db, "discussion");
    const dd = await getDocs(d);

    const t = [];

    if (dd.docs.length) {
      for (const [index, value] of (dd.docs.length ? dd.docs : []).entries()) {
        if (value.data()) {
          if( value.data()["createdBy"]) {
            t.push(value.data());
            const du = doc(db, "users", value.data()["createdBy"]);
            const ddu = await getDoc(du);
            t[t.length - 1].user = ddu.data();
            t[t.length - 1].id = value.id;
          }
         
        }
      }
    }

    // console.log(dd.docs.length);
    setData([...t]);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = (v) => {
    setSelectId(v);
    setOpenC(true);
  };

  const handleEdit = (v) => {
    setRow(v);
    setOpen(true);
  };

  const handleOpenCM = (id) => {
    setCommentId(id);
    setOpenCm(true);
  };

  return isLoading ? (
    <Box
      height="80vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={{
        marginInline: { xs: "0", md: "4rem" },
        borderRadius: "5px",
        marginBlock: "2rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: { xs: "1rem", sm: "4rem", md: "1rem", lg: "4rem" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginLeft: { xs: "1rem", sm: "4rem", md: "1rem", lg: "4rem" },
          }}
        >
          <Box
            sx={{
              mr: 1,
            }}
          >
            <ButtonViwe
              value="All"
              check={filter === ""}
              callback={() => setFilter("")}
            />
          </Box>

          <Box
            sx={{
              mr: 1,
            }}
          >
            <ButtonViwe
              value="General"
              check={filter === "General"}
              callback={() => setFilter("General")}
            />
          </Box>

          <Box
            sx={{
              mr: 1,
            }}
          >
            <ButtonViwe
              value="Career Resources"
              check={filter === "Career Resources"}
              callback={() => setFilter("Career Resources")}
            />
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <ButtonViwe
              check={filter === "Interesting Articles"}
              value="Interesting Articles"
              callback={() => setFilter("Interesting Articles")}
            />
          </Box>
        </Box>

        <Box onClick={() => setOpen(true)}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#135050",
              px: "1rem",
              py: "0.3rem",
              borderRadius: "5px",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                pr: 1,
              }}
            >
              ask a question
            </Typography>
            <ModeIcon fontSize="small" />
          </Box>
        </Box>
      </Box>
      <Box>
        <Grid sx={{ borderRadius: "5px" }} container spacing={0}>
          {data.map((val, index) => {
            if (filter.length && filter === val.category) {
              return (
                <Grid key={index} item xs={12} md={6}>
                  <Contentview
                    onClick={handleOpenCM}
                    val={val}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </Grid>
              );
            } else if (filter.length === 0) {
              return (
                <Grid key={index} item xs={12} md={12}>
                  <Contentview
                    onClick={handleOpenCM}
                    val={val}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
      </Box>

      {open && (
        <DiscussionDailog handleClose={handleClose} open={open} row={row} />
      )}

      {openCm && (
        <CommentDailog
          handleClose={handleCloseCM}
          open={openCm}
          id={commentId}
        />
      )}

      {openC && (
        <ConfirmationDailog
          handleOnClose={handleOnCloseC}
          open={openC}
          content="Are you sure, You want to delete this comment?"
          title="Delete Comment!"
        />
      )}
    </Box>
  );
};

export default Forum;

function Contentview({ val, handleDelete, handleEdit, onClick }) {
  const timeAgo = new TimeAgo("en-US");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const handleClose = async (v, reason) => {
    if (v === 1) {
      const dq = doc(db, "discussion/"+selectedId);
      const dqq = await getDoc(dq);

      // console.log(dqq.data())
      if (dqq.exists) {
        const rd = dqq.data()?.reportData ?? {};
        rd[localStorage.getItem("uId")] = {
          createdAt:Timestamp.now(),
          reason:reason
        };
        setDoc(
          dq,
          {
            reportData: rd,
          },
          { merge: true }
        );
      }
      
    }
    setSelectedId("");
    setOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        borderRadius: "5px",
        marginInline: { xs: "0rem", sm: "3rem", md: "0", lg: "3rem" },
        marginBlock: "1rem",
        backgroundColor: "#fff",
        px: 2,
      }}
    >
      <Paper elevation={3}>
        <Box
          sx={{
            paddingInline: { xs: "1rem", md: "1.5rem" },
            paddingBlock: "1rem",
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={val.user.profilePhoto}
                  alt=""
                  width="35px"
                  height="35px"
                />
                <Typography
                  variant="body1"
                  sx={{
                    pl: 1,
                  }}
                >
                  {val.user.firstName}
                </Typography>
              </Box>

              {val.createdBy !== localStorage.getItem("uId") ? (
                <Button
                  onClick={() => {
                    setSelectedId(val.id);
                    setOpen(true);
                  }}
                  size="small"
                  variant="contained"
                >
                  Report
                </Button>
              ) : null}
            </Box>
            {val.createdBy === localStorage.getItem("uId") ? (
              <Box>
                <IconButton
                  onClick={() => handleEdit(val)}
                  sx={{
                    color: "#15423F",
                  }}
                >
                  <Edit
                    sx={{
                      color: "#15423F",
                    }}
                  />
                </IconButton>
                <IconButton
                  onClick={() => handleDelete(val.id)}
                  sx={{
                    color: "#15423F",
                  }}
                >
                  <Delete
                    sx={{
                      color: "#15423F",
                    }}
                  />
                </IconButton>
              </Box>
            ) : null}
          </Box>
          <Typography
            variant="body2"
            sx={{
              pt: 1,
            }}
          >
            {val.question}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pt: 1,
            }}
          >
            <Typography variant="body2">
              {timeAgo.format(new Date(val.createdAt.toDate()))}
            </Typography>
            <Box
              onClick={() => onClick(val.id)}
              sx={{
                cursor: "pointer",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "primary.main", fontWeight: "bold" }}
              >
                {val.comment} Comment
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>

      {open && <ReportData open={open} handleClose={handleClose} />}
    </Box>
  );
}

function ButtonViwe({ value, callback, check }) {
  return (
    <Box
      sx={{
        marginBottom: "1rem",
      }}
    >
      <Button
        onClick={callback}
        sx={{
          color: check ? "#fff" : "#000",
          // border: "1px solid #135050",
          backgroundColor: check ? "#135050" : "#F4F4F4",

          borderRadius: "8px",
          px: "1rem",
          py: "0.3rem",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#135050",
            color: "#fff",
          },
        }}
      >
        {value}
      </Button>
    </Box>
  );
}
