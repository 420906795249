/* eslint-disable no-unused-vars */
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import {
  CircularProgress,
  colors,
  IconButton,
  Pagination,
  Paper,
  Tooltip,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Stack from "@mui/material/Stack";
import React, { useEffect, useRef, useState } from "react";
import ReactCardFlip from "react-card-flip";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../../service/firebase_init";
import { useNavigate, useParams } from "react-router-dom";
import { Close, Done, Flip } from "@mui/icons-material";
import PageCardDailog from "./PageCardDailog";
import frontBg from "./image/card-front-bg.png";
import backBg from "./image/card-back-bg.png";

const value = new Map();
const PageCard = () => {
  const navigator = useNavigate();

  const [note, setNote] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [subNote, setSubNote] = useState([]);
  const [page, setPage] = useState(1);
  const [open,setOpen] = useState(false);

  const onClose = ()=>{
    setOpen(false);
  }

  const handleOnChange = async (e, v) => {    
    
    setTimeout(200);    
    setPage(v);
    setFlip(false);

    const d = doc(
      db,
      "users/" + localStorage.getItem("uId") + "/cardStatus/" + fId
    );
    await setDoc(
      d,
      {
        createdAt: Timestamp.now(),
        notesId: id,
        score: v,
        subjectId: fId,
        totalCard: note.flashCardCount,
        userId: localStorage.getItem("uId"),
      },
      { merge: true }
    );    
    
  };

  const { id, fId } = useParams();

  const getData = async () => {
    setIsLoading(true);
    const data = query(doc(db, "notes/" + id + "/subChapters/", fId));
    const d = await getDoc(data);
    if (d.data()) {
      setNote(d.data());
    }
    setIsLoading(false);
  };

  const getSubNote = async () => {
    const data = collection(
      db,
      "notes/" + id + "/subChapters/" + fId + "/flashCards"
    );
    const d = await getDocs(data);

    if (d?.docs?.length) {
      const t = [];
      for (const i of d.docs) {
        t.push(i.data());
      }
      setSubNote(t);
    }
  };

  
 useEffect(() => {
    value.clear();
    getData();
    getSubNote();
  }, []);

  const [flip, setFlip] = useState(false);
  return isLoading ? (
    <Box display="flex" justifyContent="center" height="70vh">
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mt: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: { xs: "90%", md: "50%", lg: "35%" },
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#F9F9F9",
          px: { xs: "1rem", md: "2rem" },
          py: "0.8rem",
          borderRadius: "10px",
          // mt: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // mt:2
          }}
        >
          <IconButton onClick={() => navigator(-1)}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography
            variant="body1"
            sx={{
              ml: { xs: "0", md: "0.8rem" },
            }}
          >
            {note.name}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            sx={{
              color: "#565656",
            }}
          >
            {note.flashCardCount} Cards
          </Typography>
        </Box>
      </Box>

      {subNote.length === 0 ? null : (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Flip to question">
            <IconButton
              onClick={() => setFlip(false)}
              size="small"
              sx={{
                backgroundColor: colors.grey[100],
                height: "50px",
                width: "50px",
                borderRadius: "50%",
                mr: 2,
              }}
            >
              <Flip />
            </IconButton>
          </Tooltip>

          <Box>          
            <ReactCardFlip isFlipped={flip} flipDirection="vertical">
              <Box
                sx={{
                  width: { xs: "80%", sm: "320px" },
                  height: "70%",
                  
                }}
              >
                <Paper 
                  elevation={4}
                  sx={{
                    borderRadius: "18px",
                  }}
                >
                  <Box
                    onClick={() => setFlip(!flip)}
                    sx={{
                      padding: "1.5rem",
                      mt: 3,
                      cursor: "pointer",                      
                      backgroundImage: `url(${frontBg})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      borderRadius: "18px",
                      height: "490px",
                      alignContent: "space-around",
                      textAlign: "center",
                    }}
                  >
                   
                    <Box
                      sx={{
                        pt: 0.8,
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          lineHeight: "2.5rem",
                          color: "#000",
                        }}
                      >
                        {subNote[page - 1].front}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box
                sx={{
                  width: { xs: "80%", sm: "320px" },
                }}
              >
                <Paper
                  elevation={4}
                  sx={{
                    borderRadius: "18px",
                  }}
                >
                  <Box
                    onClick={() => setFlip(!flip)}
                    sx={{
                      padding: "1.5rem",
                      mt: 3,
                      cursor: "pointer",
                      backgroundImage: `url(${backBg})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      borderRadius: "18px",
                      height: "490px",
                      alignContent: "space-around",
                      textAlign: "center",
                    }}
                  >                    
                    <Box
                      sx={{
                        pt: 0.8,
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          lineHeight: "2.5rem",
                          color: "#000",
                        }}
                      >
                        {subNote[page - 1].back}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </ReactCardFlip>
          </Box>

          <Tooltip title="Flip to answer">
            <IconButton
              onClick={() => setFlip(true)}
              size="small"
              sx={{
                backgroundColor: colors.grey[100],
                height: "50px",
                width: "50px",
                borderRadius: "50%",
                ml: 2,
              }}
            >
              <Flip />
            </IconButton>
          </Tooltip>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          gap: 10,
        }}
      >
        <Box
          onClick={() => {            
            if(flip){setFlip(false);}  
            value.set(page, 1);
            if (page !== subNote.length) {
              setPage((p) => p + 1);
             
            }else if(page === subNote.length) {
              setOpen(true)
            }
          }}
          sx={{
            borderRadius: "50%",
            height: "40px",
            width: "40px",
            background: "rgb(160, 190, 156)",
            background: "linear-gradient(0deg, #93ea8f 0%, #3ff848 100%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "1px 1px 8px 1px #c0c0c0",
            margin: "10px",
            marginBottom: 0,
            cursor: "pointer",
          }}
        >
          <Done sx={{ fontSize: "30px", color: "#fff" }} />
        </Box>

        <Box
          onClick={() => {            
            if(flip){setFlip(false);}            
            value.set(page, -1);
            if (page !== subNote.length) {
              setPage((p) => p + 1);
            }else if(page === subNote.length) {
              setOpen(true)
            }
          }}
          sx={{
            borderRadius: "50%",
            height: "40px",
            width: "40px",
            background: "rgb(160, 190, 156)",
            background: "linear-gradient(0deg, #eaa18f 0%, #f8643f 100%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "1px 1px 8px 1px #c0c0c0",
            margin: "10px",
            marginBottom: 0,
            cursor: "pointer",
          }}
        >
          <Close sx={{ fontSize: "30px", color: "#fff" }} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CustomIcons
          count={note.flashCardCount}
          page={page}
          handleOnChange={handleOnChange}
        />
      </Box>
      {
        open && <PageCardDailog
        data={value.values()}
        length={subNote.length}
        onClose={onClose}
        open={open}
        />
      }
    </Box>
  );
};

export default PageCard;

function CustomIcons({ count, page, handleOnChange }) {
  return (
    <Box
      sx={{
        mt: 2,
        mb: 3,
      }}
    >
      <Stack spacing={1}>
        <Pagination
          sx={{
            mt: 3,
          }}
          size="large"
          variant="outlined"
          shape="rounded"
          onChange={handleOnChange}
          count={count}
          page={page}
          siblingCount={2}
          boundaryCount={2}
        />
      </Stack>
    </Box>
  );
}
