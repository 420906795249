import { Box, Typography, Grid, Button, CircularProgress } from "@mui/material";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../service/firebase_init";
import TimeAgo from 'javascript-time-ago';
import CommentDailog from "../Dailog/CommentDailog";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const ExplorePage = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [commentId, setCommentId] = useState(0);
  const [openCm, setOpenCm] = useState(false);

  const getData = async () => {
    try {
      setIsLoading(true);
      const d = collection(db, "discussion");
      const dd = await getDocs(d);

      const t = [];

      if (dd.docs.length) {

        // eslint-disable-next-line no-unused-vars
        for (const [index, value] of (dd.docs.length ? dd.docs : []).entries()) {

          if (value.data()) {
            if (value.data()["createdBy"]) {
              t.push(value.data());
              const du = doc(db, "users", value.data()["createdBy"]);
              const ddu = await getDoc(du);
              t[t.length - 1].user = ddu.data();
            }
          }
        }
      }

      setData([...t]);
      setIsLoading(false);
    } catch (e) { }
  }

  useEffect(() => {
    getData();
  }, []);

  const handleOpenCM = (id) => {
    setCommentId(id)
    setOpenCm(true);
  }

  const handleCloseCM = (v) => {
    if (v) {
      getData();
    }
    setOpenCm(false);
  };

  const responsiveCarouselConfig = {
    0: {
      items: 1
    },
    990: {
      items: 2,
      itemsFit: 'contain'
    },
    1200: {
      items: 3,
      itemsFit: 'contain'
    }
  };

  return (isLoading ?
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
    :
    <Box
      width="100vw"
    >
      <Box
        sx={{
          backgroundColor: "#F2F2F2",
          marginInline: { xs: "1rem", md: "2rem", lg: "3rem" },
          marginBottom: "2rem",

        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyItems: "center",
            marginTop: "2rem",
            paddingBlock: "1.5rem",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              pb: 1,
              textAlign: "center",
              fontWeight: "700"
            }}
          >
            Explore What People Are Discussing
          </Typography>
        </Box>
        <Box>
          <AliceCarousel container responsive={responsiveCarouselConfig} disableDotsControls={true} disableSlideInfo={true} disableButtonsControls={true} autoPlay={true} autoPlayInterval={3000} infinite={true}>
            {
              data.map((val, index) => {
                return <div key={index} className="testimonialCardDiv">
                  <Contentview className="commentTextArea" val={val} onClick={handleOpenCM} />
                </div>
              })
            }
          </AliceCarousel>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
        </Box>
      </Box>
      {openCm && <CommentDailog handleClose={handleCloseCM} open={openCm} id={commentId} />}
    </Box>
  );
};

export default ExplorePage;

function Contentview({ val, onClick }) {
  const timeAgo = new TimeAgo('en-US');
  return (
    <Box className="commentTextAreaDiv"
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        borderRadius: "5px",
        marginInline: { xs: "1rem", sm: "4rem", md: "1rem", lg: "3rem" },
        marginBlock: "1rem",
        backgroundColor: "#fff",
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={val.user.profilePhoto} alt="" width="35px" height="35px" />
        <Typography
          variant="body1"
          sx={{
            pl: 1,
          }}
        >
          {val.user.firstName}
        </Typography>
      </Box>
      <Typography className="commentTextAreaContent" variant="body2" sx={{ pt: 1, }}>{val.question}</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: 1,
        }}
      >
        <Typography variant="body2">{timeAgo.format(new Date(val.createdAt.toDate()))}</Typography>
        <Box
          onClick={() => onClick(val.id)}
          sx={{
            cursor: "pointer"
          }}
        >
          <Typography variant="body2" sx={{ color: "primary.main", fontWeight: "bold" }}>{val.comment} Comment</Typography>

        </Box>
      </Box>
    </Box>
  );
}
