import { Box, Paper, Typography } from "@mui/material";
import { useNavigate, useSearchParams} from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import TextFieldView from "../../Components/TextField/TextFieldView";
import LoadingButtonView from "../../Components/Button/LoaderButton";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth, signInWithPopupC } from "../../service/firebase_init";
import PlanAuth from "./PlanAuth";
import { APP_NAME_SMALL } from "../../App.js";

function LoginAuth() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const navigation = useNavigate();

  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState();
  const [urlPerm, setUrlPerm] = useSearchParams();
  const [amt, setAmt] = useState(null);
  const [pkg, setPkg] = useState(null);
  const [checkout, setCheckout] = useState(false);
  const [index, setIndex] = useState(-1);

  const onLoginStart = useCallback(() => {
    alert("login start");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    alert("logout success");
  }, []);
  // const auth = UserContext();

  const handleLogin = async () => {
    if (!email) {
      alert("Please fill email id");
    } else if (!password) {
      alert("Please fill password");
    } else {
      setLoading(true);

      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          // const user = userCredential.user;
          localStorage.setItem('cred', userCredential);
          setLoading(false);
          const amount = urlPerm.get("amount");
          const packageV = urlPerm.get("package");
          let q = "";
          if (amount && packageV) {
            const a = amount ? "?amount=" + amount : "";
            const p = packageV ? "&package=" + packageV : "";
            q = a + p;
          }
          navigation("/home" + q);
          // console.log(user);
        })
        .catch(async (error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // console.log(errorCode, errorMessage)
          setLoading(false);
          if (errorCode === "auth/user-not-found") {
            const { user } = await createUserWithEmailAndPassword(
              auth,
              email,
              password
            );

            if (!user) {
              alert("Oops! Something went wrong. Please try again.");
            }
            // alert("User not found.");
          } else if (errorCode === "auth/wrong-password") {
            alert("Wrong password.");
          }
        });
    }
  };

  const handleEmail = (v) => {
    setEmail(v);
  };

  const handlePassword = (v) => {
    setPassword(v);
  };

  useEffect(() => {
    const amount = urlPerm.get("amount");
    const packageV = urlPerm.get("package");

    if (amount && packageV) {
      setAmt(amount);
      setPkg(packageV);

      if(packageV === "com.nursingfocus.yearly"){
        setIndex(0);
      }else if(packageV === "com.nursingfocus.sixmonth"){
        setIndex(1);
      }else if(packageV === "com.nursingfocus.monthly"){
        setIndex(2);
      }else {
        setIndex(-1);
      }
    }else {
      setCheckout(true);
    }
  }, []);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {(pkg && amt && index !== -1) &&  (
        <PlanAuth show={checkout} index={index} onCall={(v) => setCheckout(v)} />
      )}

      {
        checkout ?
        <Paper
        elevation={0}
        sx={{
          backgroundColor: "#09383E",
          width: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 2.5,
          px: 5,
          border: "solid",
          borderRadius: "20px",
          borderColor: "background.paper",
          borderWidth: "0.5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 1,
            marginTop: 2,
          }}
        >
          <img
            src="logoWhite.png"
            alt="logo"
            style={{ height: "35px", color: "white", marginRight: "10px" }}
          />
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold",
              fontSize: "150px",
            }}
          >
            {APP_NAME_SMALL}
          </Typography>
        </Box>
        <Typography
          variant="h6"
          sx={{
            mt: 1.3,
            mb: 0.3,
            textAlign: "center",
            fontWeight: "bold",
            color: "white",
          }}
        >
          Welcome Back !!
        </Typography>
        <Typography
          variant="body2"
          sx={{ textAlign: "center", color: "white" }}
        >
          Glad to see you here again.
        </Typography>

        {/* TEXTFIELD */}
        <TextFieldView
          label="Email"
          value={email}
          onchange={handleEmail}
          type="email"
          sx={{ mt: 1, mb: 1, width: "100%" }}
        />
        <TextFieldView
          label="Password"
          value={password}
          onchange={handlePassword}
          type="password"
          sx={{ mb: 0.5, width: "100%" }}
        />

        {/* REMBER & FORGOT PASSWORD */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <FormControlLabel
              labelPlacement="end"
              control={
                <Checkbox
                  onChange={(e) => {
                    setIsRemember(e.target.checked);
                  }}
                />
              }
              label={
                <Typography sx={{ fontSize: "15px" }} variant="body">
                  Remember me
                </Typography>
              }
            /> */}

          {/* <Button sx={{ px: 0, texttransform: "none" }} variant="text">
              <Typography variant="body2" sx={{ textTransform: "none" }}>
                Forgot pwd?
              </Typography>
            </Button> */}
        </Box>

        {/* BUTTON */}
        <LoadingButtonView
          loading={loading}
          lable="Continue"
          onClick={() => handleLogin()}
          variant="contained"
          sx={{
            width: "100%",
            mt: 1,
            height: "40px",
            backgroundColor: "white",
          }}
        />
        <Typography
          variant="body2"
          sx={{
            my: 2,
            color: "#ffffffce",
          }}
        >
          or continue with
        </Typography>
        <Box
          sx={{
            display: "flex",
          }}
        >
          {/* <LoginSocialGoogle
          client_id={"668809113439-5p194mr9b7ap64m1842pqt2pk5aujeim.apps.googleusercontent.com" || ''}
          onLoginStart={onLoginStart}
          // redirect_uri={REDIRECT_URI}
          scope="openid profile email"
          discoveryDocs="claims_supported"
          access_type="offline"
          onResolve={async({ provider, data }) => {
            console.log("provider",provider);
            console.log("data",data);
            setProvider(provider);
            setProfile(data);
          
           const credential =   GoogleAuthProvider.;
          const user = await  signInWithCredentialC(auth,credential);
          console.log("user",user.user.uid)
          
          //  auth.fi
          }}
          onReject={err => {
            console.log(err);
          }}
        > */}
          <Box
            onClick={async () => {
              const provider = new GoogleAuthProvider();
              provider.addScope("profile");
              provider.addScope("email");
              const result = await signInWithPopupC(auth, provider);              
              // // The signed-in user info.
              // const user = result.user;
              // // This gives you a Google Access Token.
              // const credential =
              //   GoogleAuthProvider.credentialFromResult(result);
              // const token = credential.accessToken;
            }}
          >
            <SocialContainer icon={"google.png"} />
          </Box>

          {/* </LoginSocialGoogle> */}

          {/* <LoginSocialFacebook
            appId={"1947539065446252" || ""}
            fieldsProfile={
              "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
            }
            onLoginStart={onLoginStart}
            onLogoutSuccess={onLogoutSuccess}
            // redirect_uri={REDIRECT_URI}
            onResolve={({ provider, data }) => {
              console.log("provider", provider);
              console.log("data", data);
              setProvider(provider);
              setProfile(data);
            }}
            onReject={(err) => {
              console.log(err);
            }}
          > */}

          <Box
            onClick={async () => {
              const provider = new FacebookAuthProvider();
              // provider.addScope("profile");
              // provider.addScope("email");
              const result = await signInWithPopupC(auth, provider);
            }}
          >
            <SocialContainer icon={"facebook.png"} />
          </Box>
          {/* </LoginSocialFacebook> */}
          <Box
            onClick={async () => {
              const provider = new OAuthProvider("apple.com");
              provider.addScope("name");
              provider.addScope("email");
              const result = await signInWithPopupC(auth, provider);
            }}
          >
            <SocialContainer icon={"apple.png"} />
          </Box>
        </Box>
      </Paper> : null
      }
    </Box>
  );
}

export default LoginAuth;

function SocialContainer({ icon }) {
  return (
    <Box
      sx={{
        height: "35px",
        width: "35px",
        borderRadius: "8px",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mx: 0.8,
        cursor: "pointer",
      }}
    >
      <img src={icon} alt="icon" style={{ height: "20px", width: "20px" }} />
    </Box>
  );
}
