import { loadStripe } from '@stripe/stripe-js';

const stripe_sk_live = process.env.STRIPE_KEY_LIVE;
const stripe_sk_test = process.env.STRIPE_KEY_TEST;

let stripePromise;
const getStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(stripe_sk_live);
  }
  return stripePromise;
};

export default getStripe;