import { Box } from '@mui/material'
import React from 'react';
import DetailsLink from "../../Components/Notes/Details/DetailsLink"
import Details from "../../Components/Notes/Details/Details"

function NotesDetailsPage() {
  return (
    <Box>
         <DetailsLink/>
           <Details/>
    </Box>
  )
}

export default NotesDetailsPage