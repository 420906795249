/* eslint-disable no-unused-vars */
import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { Button, Divider } from "@mui/material";
import moment from "moment";

const QuizPagethree = ({com,start,scorQ,totalQ,attempQ, notAttemp, correctAns, inCorrectAns}) => {
  const [progress, setProgress] = React.useState(scorQ);

  // console.log(scorQ)

  const getTimeDiff = ()=>{
    console.log(moment(com.toDate(),"DD/MM/YYYY HH:mm:ss"), moment(start.toDate(),"DD/MM/YYYY HH:mm:ss"))
    var ms = moment(com.toDate(),"DD/MM/YYYY HH:mm:ss").diff(moment(start.toDate(),"DD/MM/YYYY HH:mm:ss"));
    var d = moment.duration(ms);
    var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
    return s;
  }

  React.useEffect(() => {
    // const timer = setInterval(() => {
    //   setProgress((prevProgress) =>
    //     prevProgress >= 100 ? 0 : prevProgress + 1
    //   );
    // }, 800);
    // return () => {
    //   clearInterval(timer);
    // };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginBlock: "3rem",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <CircularProgressWithLabel value={Math.round(scorQ)} />
      <Divider orientation="vertical" />

      <TextViwe
        value="Total Question :"
        title={totalQ}
        subtitle="Correct Answer :"
        number={correctAns}
      />
      <TextViwe
        value="Incorrect Answer :"
        title={inCorrectAns}
        subtitle="Not Attempted :"
        number={notAttemp}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextViwe time={getTimeDiff()}>
          <AccessAlarmIcon color="secondary" fontSize="large" />
        </TextViwe>
        <Box
          sx={{
            display: "flex",
            marginTop: { xs: "1rem", md: "0" },
            paddingLeft: "2rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Button
            sx={{
              backgroundColor: "#135050",
              color: "#fff",
              border: "1px solid #135050",
              borderRadius: "8px",
              px: "1rem",
              py: "0.5rem",
              textTransform: "none",
              "&:hover": {
                color: "#135050",
              },
            }}
          >
            Share Result
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
};

export default QuizPagethree;

function CircularProgressWithLabel(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: { xs: "none", md: "flex" },
        paddingInline: "2rem",
      }}
    >
      <CircularProgress variant="determinate" color="secondary" {...props} />

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
function TextViwe({ value, subtitle, title, number, time, children }) {
  return (
    <Box display="flex">
      <Box
        sx={{
          paddingInline: "2rem",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
          }}
        >
          <Box display="flex">
            <Typography variant="body2">{value}</Typography>
            <Typography
              sx={{
                fontWeight: "550",
              }}
              variant="body2"
            >
              {title}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="body2">{subtitle}</Typography>
            <Typography
              sx={{
                fontWeight: "550",
              }}
              variant="body2"
            >
              {number}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {children}
          <Typography
            variant="h3"
            sx={{
              pl: 1,
            }}
          >
            {time}
          </Typography>
        </Box>
      </Box>
      <Divider orientation="vertical" />
    </Box>
  );
}
