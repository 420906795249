/* eslint-disable no-unused-vars */
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import Card from "../../Components/Home/Card";
import ExplorePage from "../../Components/Home/ExplorePage";
import OurFlashcards from "../../Components/Home/OurFlashcards";
import OurNotes from "../../Components/Home/OurNotes";
import Quiz from "../../Components/Home/Quiz";
import { Stripe } from 'stripe';
import { stripe_sk, cancel_url_var, success_url_var } from "../../variables";

import { db, auth } from "../../service/firebase_init";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc
} from "firebase/firestore";
import getStripe from "../../service/get_stripe";
//import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import qs from "qs";
import Plan from "../Plan";
import { isUserPremium } from "../../App";
import { useSearchParams, useNavigate } from "react-router-dom";

function HomePage() {
  const [setting, setSetting] = useState(null);
  const [uId, setUId] = useState(null);
  const [urlPerm, setUrlPerm] = useSearchParams();
  const stripe_api = Stripe(stripe_sk);
  const navigator = useNavigate();
  const getData = async () => {
    const data = collection(db, "settings");
    const d = await getDocs(data);

    if (
      d
        ?.docs
        ?.length > 0) {
      setSetting(d.docs[0].data());
    }
  };

  const [sLoader, setSLoader] = useState(false);

  async function handleCheckout(amount, packageV) {
    // console.log("isUserPremium1",isUserPremium);
    if (isUserPremium) {
      return;
    } else {
      const stripe = await getStripe();
      const priceId = await getPriceId(packageV);
      if (!priceId) {
        return null;
      }
      const d = doc(db, "users/" + localStorage.getItem("uId"));
      const u = await getDoc(d);
      const session = await stripe_api.checkout.sessions.create({
        line_items: [
          {
            price: priceId,
            quantity: 1,
          },  //Replace with your price ID and desired quantity
        ],
        mode: "subscription",
        cancel_url: cancel_url_var,
        success_url: success_url_var,
        customer_email: u.data()["email"],
      });
      const data = doc(db, "users", auth.currentUser.uid);
      await setDoc(data, {
        isSubscribe: false,
        subscribetionpackge: packageV,
        session_id: session.id
      }, { merge: true });
      window.open(session.url, "_self");
    }
    setOpen(false);
  }

  const getPriceId = async pname => {
    let name = "";
    if (pname === "com.nursingfocus.yearly") {
      name = "NR-Yearly";
    } else if (pname === "com.nursingfocus.six_month") {
      name = "NR-Six Month";
    } else if (pname === "com.nursingfocus.monthly") {
      name = "NR-Monthly";
    }
    const { data } = await axios.get("https://api.stripe.com//v1/products", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + stripe_sk,
      }
    });

    if (data && data.data) {
      const product = data.data.filter(v => v.name === name);
      if (product && product.length) {
        const { data: dataPrice } = await axios.get("https://api.stripe.com//v1/prices", {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + stripe_sk,
          }
        });
        const price = dataPrice.data.filter(v => v.product === product[0].id);
        if (price && price.length) {
          return price[0].id;
        }
        return null;
      }
    }

    return null;
  };

  const onToken = token => {
    console.log(token);
    fetch("/save-stripe-token", {
      method: "POST",
      body: JSON.stringify(token)
    }).then(response => {
      response.json().then(data => {
        console.log(data);
        alert(`We are in business, ${data.email}`);
      });
    }).catch(err => console.log(err));
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {        
        const uid = user.uid;
        setUId(uid);    
        getData();
        const amount = urlPerm.get("amount");
        const packageV = urlPerm.get("package");
        if (amount && packageV) {
          handleCheckout(amount, packageV);
        }    
      } else {
        // User is signed out
        localStorage.removeItem("uId");
        setUId(null);
        navigator("/web");
      }
    });
    
  }, []);

  const [open, setOpen] = useState(false);
  const handleOnOpen = () => {
    if (isUserPremium) {
      alert("You have already subscribed !");
    } else {
      setOpen(true);
    }
  };

  return (<Box id="Home">
    {
      setting
        ? (<img onClick={handleOnOpen} src={setting.media} alt="" width="100%" height="clamp(200px,70vh,600px)" style={{
          cursor: isUserPremium
            ? "auto"
            : "pointer",
          margin: "0",
          objectFit: "fill",
          padding: "0",
          marginBottom: "1rem"
        }} />)
        : null
    }
    
    <Card />
    <OurNotes />
    <OurFlashcards />
    <Quiz />
    {/* <VideoCourse/> */}
    <ExplorePage /> {
      open && !isUserPremium
        ? (<Plan handleOnClose={() => setOpen(false)} open={open} sLoader={sLoader} setSLoader={setSLoader} onCall={(v, packageV) => {
          handleCheckout(v, packageV);
        }} />)
        : null
    }

    
  </Box>);
}

export default HomePage;
