

import React from "react";
import { Box, MenuItem } from "@mui/material";
import { Link, Typography } from "@mui/material";
import Arrow from "./image/Arrow.png";
import { useNavigate } from "react-router-dom";

const VideoCourseLink = () => {
  const navigator = useNavigate();
  return (

      <Box
        sx={{
          backgroundColor: "#F2F2F2",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F2F2F2",
            display: "flex",
            alignItems: "center",
            height: "3.1rem",

            pl: { xs: "0.5rem", md: "4rem" },
          }}
        >
          <Link
            // href="#"
            variant="body2"
            sx={{
              color: "#009D9D",
            }}
            onClick={()=>navigator("/home")}
          >
            Home
          </Link>
          <Link
            // href="#"
            underline="none"
            sx={{
              color: "#000",
              px: "0.4rem",
            }}
          >
            /
          </Link>

          <Link
            href=""
            variant="body2"
            sx={{
              color: "#000",
            }}
          >
          Video Course
          </Link>
          
          
        </Box>

        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pr: { xs: "0.5rem", md: "4rem" },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              display: { xs: "none", sm: "flex" },
            }}
          >
            Sort By
          </Typography>
          <Box>
            <MenuItem
              sx={{
                background: { xs: "none", md: "#fff" },

                ml: "0.8rem",
                borderRadius: "6px",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <em>Latest</em>
              </Typography>
              <img
                src={Arrow}
                alt=""
                style={{
                  paddingLeft: "0.5rem",
                }}
              />
            </MenuItem>
          </Box>
        </Box> */}

      </Box>
    
  );
};

export default VideoCourseLink;
