import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
  } from "@mui/material";
  import React from "react";
  
  function ConfirmationDailog({
    open,
    handleOnClose,
    title = "Delete Banner",
    content = "Are you sure, You want to delete this banner.",
  }) {
    return (
      <>
        <Dialog open={open} onClose={() => handleOnClose(0)}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContentText sx={{ width: "350px", mt: 1, px: 3 }}>
            {content}
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => handleOnClose(1)} variant="text">
              Yes
            </Button>
  
            <Button onClick={() => handleOnClose(0)} variant="text">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  
  export default ConfirmationDailog;
  