import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../service/firebase_init";
import MultiSelection from "../Multiselection/Multiselection";

function DiscussionDailog({ handleClose, open,row }) {
  const [discussion, setDiscussion] = useState(row !== null ? row.question : "");
  const [selectedCategory, setSelectedCategory] = useState(row !== null ? [row.category]:[]);
  const [loading, setLoading] = useState(false);
  const type = ["General", "Career Resources", "Interesting Articles"];

  const handleDiscussion = (e) => {
    const {
      target: { value },
    } = e;
    setDiscussion(value);
  };

  const handleSelectCategory = async (e) => {
    const {
      target: { value },
    } = e;
    setSelectedCategory([value]);
  };

  const submit = async()=>{
    if(!selectedCategory.length){
        alert("Please select category");
    }else if(!discussion.length){
        alert("Please fill details");
    }else {
        setLoading(true);
        const i = row !== null ? row.id : Date.now();
        const d = doc(db,"discussion/"+i);

        await setDoc(d,{
            category: selectedCategory[0],
            comment:0,
            createdAt:Timestamp.now(),
            createdBy:localStorage.getItem("uId"),
            hitCount:0,
            id:i,
            isDelete:false,
            isDeleteByAdmin:false,
            question:discussion
        });
        setLoading(false);
        handleClose(1);
    }
  }

  return (
    <Dialog onClose={()=>handleClose(0)} open={open}>
      <DialogTitle>{row === null ? "Create" :"Update"} Discussion</DialogTitle>
      <DialogContent
      sx={{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center"
      }}
      >
        <MultiSelection
        lable="Category"
        names={type}
        multiple={false}
        handleChange={handleSelectCategory}
        selected={selectedCategory}/>

        <TextField
          fullWidth
          size="small"
          multiline
          maxRows={4}
          rows={4}
          variant="outlined"
          onChange={handleDiscussion}
          value={discussion}
          placeholder="Enter details..."
          sx={{width:"95%"}}
        />
        

      </DialogContent>
      <DialogActions>
      {
        loading ? <CircularProgress/> : <Button variant="text" onClick={() => submit()}>
       {row === null ? "Create" :"Update"}
      </Button>
      }  
        <Button variant="text" onClick={() => handleClose(0)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DiscussionDailog;
