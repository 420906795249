import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import play from "./image/play-store 1.png";
import app from "./image/app-store 1.png";
import { APP_NAME_SMALL, APPLE_URL, ANDROID_URL } from "../../App.js";

const Home = () => {
  return (
    <Box
      px={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="#262626"
      color="primary.main"
      height="3rem"
      
    >
      <Box>
        <Typography
          variant="body1"
          sx={{
            color:"#fff",
            textAlign: { xs: "center", md: "start" },
          }}
        >
          {APP_NAME_SMALL} is also available in
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="#12B1B1"
      >
        <AppView url= {ANDROID_URL} image={play} value="Get it on Google Play" />
        <AppView url= {APPLE_URL} image={app} value="Get it on App Store" />
      </Box>
    </Box>
  );
};

export default Home;

function AppView({ image, value,url }) {
  return (
    <Box display="flex" alignItems="center" ml="8px" sx={{cursor:"pointer"}} 
    onClick={()=>window.open(url)}
    >
      <img
        src={image}
        alt=""
        min-width="18px"
        height="16px"
        sx={{ marginRight: "-5px" }}
      />
      <Typography
        sx={{
          textAlign: { xs: "center", md: "start" },
          display:{xs:"none",sm:"flex"}
        }}
        variant="body2"
        pl="0.6rem"
      >
        {value}
      </Typography>
    </Box>
  );
}
