import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

function PageCardDailog({ open, onClose, length, data }) {
  const t = [...data];
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Results</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "5px",
        }}
      >
        <Typography
          sx={{
            backgroundColor: "#c0c0c079",
            padding: "8px",
            margin: "5px",
            borderRadius: "10px",
          }}
        >
          Attempted - {t.filter((v) => v === 1).length}
        </Typography>

        <Typography
          sx={{
            backgroundColor: "#c0c0c079",
            padding: "8px",
            margin: "5px",
            borderRadius: "10px",
          }}
        >
          Not Attempted - {t.filter((v) => v === -1).length}
        </Typography>

        <Typography
          sx={{
            backgroundColor: "#c0c0c079",
            padding: "8px",
            margin: "5px",
            borderRadius: "10px",
          }}
        >
          Skipped - {length - t.length}
        </Typography>

        <Typography
          sx={{
            backgroundColor: "#c0c0c079",
            padding: "8px",
            margin: "5px",
            borderRadius: "10px",
          }}
        >
          Total - {length}
        </Typography>

       
      </DialogContent>
      <DialogActions>
      <Button
          variant="text"
          sx={{
            alignSelf: "flex-end",
          }}
          onClick={onClose}
        >
          <Typography> Close</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PageCardDailog;
