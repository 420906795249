import { Box } from "@mui/material";
import React from "react";
import Notes from "../../Components/Notes/Notes";

function NotePage() {

  // console.log("call")
  return (
    <Box>
      <Notes />
      {/* <ButtonMore /> */}
    </Box>
  );
}

export default NotePage;
