import React from "react";
import { Box, TextField } from "@mui/material";

import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      backgroundColor: "#286F6F"
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "#286F6F",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#286F6F"
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "#286F6F"
    }
  }
}));

function TextFieldView({
  onchange,
  label,
  value,
  type = "text",
  variant = "outlined",
  sx = {},
  required = false
}) {
  const classes = useStyles();

  const styles = (theme) => createStyles({
    input: {
        '&::placeholder': {
          color: 'white',
        },
        color: 'white',
      },
    });

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        ...(sx),borderRadius:2
      }}
    >
      <TextField
     
      className={classes.root}
        required={required}
        size="small"
        sx={{ ...sx, backgroundColor:"#286F6F",borderRadius:2, }}
        // label={label}
        placeholder={label}
        type={type}
        value={value}
        onChange={(e) => {
          onchange(e.target.value);
        }}
        variant={variant}
      />
    </Box>
  );
}

export default TextFieldView;
