import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React, { useState } from 'react'
import { DatePicker } from "@mui/x-date-pickers";

function PlannerDailog({handleClose,open}) {
    const [dateTime, setDateTime] = useState(new Date());
    const handleDateTime = (v) => {
      
        setDateTime(v);
      };

  return (
    <Dialog
    onClose={handleClose}
    open={open}
    >
        <DialogTitle>Create Planner</DialogTitle>
        <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            // disabled={!(status === 1)}
            label="Due Date"
            value={dateTime}
            onChange={handleDateTime}
            minDate={Date.now()}
            renderInput={(params) => <TextField sx={{mt:1}} {...params} />}
          />
        </LocalizationProvider>
        </DialogContent>
        <DialogActions>
            <Button variant="text" onClick={()=>handleClose(1,dateTime)}>Create</Button>
            <Button variant="text" onClick={()=>handleClose(0)}>Cancel</Button>
        </DialogActions>
    </Dialog>
  )
}

export default PlannerDailog