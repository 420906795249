import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React, { useState } from 'react'
import { DatePicker } from "@mui/x-date-pickers";

function EditPlannerDailog({handleClose,open,row}) {
    // console.log(row);
    const [dateTime, setDateTime] = useState(row.dueDate.toDate());

    const handleDateTime = (v) => {
        setDateTime(v);
      };

  return (
    <Dialog
    onClose={()=>handleClose(0)}
    open={open}
    >
        <DialogTitle>Update Planner</DialogTitle>
        <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            // disabled={!(status === 1)}
            label="Due Date"
            value={dateTime}
            onChange={handleDateTime}
            minDate={Date.now()}
            renderInput={(params) => <TextField sx={{mt:1}} {...params} />}
          />
        </LocalizationProvider>
        </DialogContent>
        <DialogActions>
            <Button variant="text" onClick={()=>handleClose(1,dateTime)}>Update</Button>
            <Button variant="text" onClick={()=>handleClose(0)}>Cancel</Button>
        </DialogActions>
    </Dialog>
  )
}

export default EditPlannerDailog