import { Box, Link } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const PlannerLink = () => {
    const navigator = useNavigate();
  return (
    <Box
        sx={{
          backgroundColor: "#F2F2F2",
          display: "flex",
          alignItems: "center",
          
          height: "3.1rem",
          pl: "2rem",
        }}
      >
        <Link
         
          variant="body2"
          sx={{
            color: "#009D9D",
          }}
          onClick={()=>navigator("/home")}
        >
          Home
        </Link>
        <Link
          
          underline="none"
          sx={{
            color: "#000",
            px: "0.4rem",
          }}
        >
          /
        </Link>

        <Link
          href=""
          variant="body2"
          sx={{
            color: "#000",
          }}
        >
       Study Planner
        </Link>
      </Box>
  )
}

export default PlannerLink