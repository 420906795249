import { Box } from "@mui/material";
import React from "react";
import QuizPagetwoLink from "../../Components/Quiz/Quiz Page two/QuizPagetwoLink";
import QuizPagetwo from "../../Components/Quiz/Quiz Page two/QuizPagetwo";


function QuizSet() {

  

  return (
    <Box>
      <QuizPagetwoLink />
      <QuizPagetwo />
    </Box>
  );
}

export default QuizSet;
