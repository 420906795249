import React, { useEffect, useState } from "react";
import { db, auth } from "../../service/firebase_init";
import { Timestamp, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Stripe } from 'stripe';
import axios from 'axios';
import { isUserPremium } from "../../App";
import { stripe_sk } from "../../variables";

function Status() {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("Checking payment status");
  const navigator = useNavigate();
  const stripe = Stripe(stripe_sk);

  const getEndDates = (d) => {

    let days = 0;

    if (d === "com.nursingfocus.monthly") {
      days = 30;
    } else if (d === "com.nursingfocus.six_month") {
      days = 180;
    } else if (d === "com.nursingfocus.yearly") {
      days = 360;
    }

    const dd = new Date();
    return new Date(dd.setMonth(dd.getMonth() + days / 30));
  }

  const getData = async () => {
    //console.log("auth",auth.currentUser,localStorage.getItem("uId"))
    const data = doc(db, "users", localStorage.getItem("uId"));

    const d = await getDoc(data);

    if (d.exists()) {
      console.log(d.data());
      const getEndDate = getEndDates(d.data().subscribetionpackge);

      const session = await stripe.checkout.sessions.retrieve(
        d.data().session_id
      );
      console.log(session, 'data');
      if (session.status === "complete") {
        await setDoc(
          data,
          {
            subscriptionID: session.subscription,
            isSubscribe: true,
            subscribetiondate: Timestamp.now(),
            subscriptionEndDate: Timestamp.fromDate(getEndDate),
            payment_intent: session.payment_intent,
            paymentMode: "v2"
          },
          { merge: true }
        );
        //isUserPremium = true;
        setStatus("Payment done successfully");
        window.open("/home", "_self");
      } else {
        setStatus("Oops! something went wrong.");
      }
      setIsLoading(false);
      // console.log(JSON.stringify(response.data));
    } else {
      navigator("/home");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh"
      }}
    >
      {isLoading ? (
        <Box>
          <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
            Checking Status...
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>Please wait...</Typography>
        </Box>
      ) : (
        <Box>
          <Typography>{status}</Typography>
          <Button
            onClick={() => navigator("/home")}
            sx={{ fontWeight: "bold", fontSize: "20px" }}
          >
            Go to home page
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default Status;
