import { Box } from "@mui/material";
import React from "react";
import PageCardLink from "../../Components/FlashCard/PageCard/PageCardLink"
import PageCard from "../../Components/FlashCard/PageCard/PageCard"
function CardDetailsView() {
  return (
    <Box>
      {" "}
      <PageCardLink />
      <PageCard />
    </Box>
  );
}

export default CardDetailsView;
