import { Box } from "@mui/material";
import React from "react";
import QuizPageoneLink from "../../Components/Quiz/Quiz Page one/QuizPageoneLink";
import QuizPageone from "../../Components/Quiz/Quiz Page one/QuizPageone";

function QuizDetails() {
  return (
    <Box>
      <QuizPageoneLink />
      <QuizPageone />
    </Box>
  );
}

export default QuizDetails;
