/* eslint-disable no-unused-vars */
 import React from "react";
 import { Box } from "@mui/material";
 import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
 
 const QuizPagefourLink = () => {
  const navigator = useNavigate();
   return (
     <Box
       sx={{
         backgroundColor: "#F2F2F2",
         display: "flex",
         alignItems: "center",
       }}
     >
       <Box
         sx={{
           backgroundColor: "#F2F2F2",
           display: "flex",
           alignItems: "center",
           height: "3.1rem",
 
           pl: { xs: "0.5rem", md: "4rem" },
         }}
       >
         <Link
           href="#"
           variant="body2"
           sx={{
             color: "#009D9D",
           }}
           onClick={()=>navigator("/")}
         >
           Home
         </Link>
         <Link
           href="#"
           underline="none"
           sx={{
             color: "#009D9D",
             px: "0.4rem",
           }}
         >
           /
         </Link>
 
         <Link
           href=""
           variant="body2"
           sx={{
             color: "#009D9D",
           }}
           onClick={()=>navigator("/quiz")}
         >
      Quiz
         </Link>
         <Link
           href="#"
           underline="none"
           sx={{
             color: "#009D9D",
             px: "0.4rem",
           }}
         >
           /
         </Link>
         <Link
           href=""
           variant="body2"
           sx={{
             color: "#000",
           }}
         >
         Create Test
         </Link>
         
         
       </Box>
     </Box>
   );
 };
 
 export default QuizPagefourLink;
 