import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
} from "@mui/material";
// import Header from "../CommonSection/Header";
import Shape from "./Shape.png";
// import { PackData } from "../../utils/Constent/Home/HomeConstent";

const PlanAuth = ({ show, onCall, index }) => {
  const PackData = [
    {
      value: "Yearly Pack",
      subtitle1: "Helped over 100,000 Nursing students",
      subtitle2:
        "96% PassRate 3000 Questions & Explanation 600+ Study Guide & Cheat sheets",
      subtitle3: "Track your progress with Study planner",
      subtitle4: "Mobile & Website access",
      price: "At Just USD 59.99",
      valueP: 59.99,
      packageV: "com.nursingfocus.yearly",
    },
    {
      value: "Six Month Pack",
      subtitle1: "Helped over 100,000 Nursing students",
      subtitle2:
        "96% PassRate 3000 Questions & Explanation 600+ Study Guide & Cheat sheets",
      subtitle3: "Track your progress with Study planner",
      subtitle4: "Mobile & Website access",
      price: "At Just USD 34.99",
      valueP: 34.99,
      packageV: "com.nursingfocus.six_month",
    },
    {
      value: "Monthly Pack",
      subtitle1: "Helped over 100,000 Nursing students",
      subtitle2:
        "96% PassRate 3000 Questions & Explanation 600+ Study Guide & Cheat sheets",
      subtitle3: "Track your progress with Study planner",
      subtitle4: "Mobile & Website access",
      price: "At Just USD 6.99",
      valueP: 6.99,
      packageV: "com.nursingfocus.monthly",
    },
  ];

  return (

      <Box
      sx={{
        mb:2
      }}
      >

        <Box>
          <Box
            sx={{
              backgroundColor: "#135050",
              borderRadius: "10px",
              p: 1,
            }}
          >
            <Box
              
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <PackView
                      {...PackData[index]}
                      onCall={onCall}
                      show={show}
                    />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
  
  );
};

export default PlanAuth;

function PacktextView({ subtitle }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        pt: 2,
        px: 2,
      }}
    >
      <Box>
        <img src={Shape} alt="" width="24px" />
      </Box>
      <Box
        sx={{
          pl: 2,
        }}
      >
        <Typography variant="body2" color="#252525">
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}

function PackView({
  subtitle1,
  subtitle2,
  subtitle3,
  subtitle4,
  value,
  price,
  onCall,
  show,
}) {
  return (
    <Card
      sx={{
        background: "#fff",
        p: 2,
      }}
    >
      <Box>
        <Box
          sx={{
            pb: 1,
            pl: 4,
          }}
        >
          <Typography variant="h3" color="#135050">
            {value}
          </Typography>
        </Box>
        <Box>
          <PacktextView subtitle={subtitle1} />
        </Box>
        <Box>
          <PacktextView subtitle={subtitle2} />
        </Box>
        <Box>
          <PacktextView subtitle={subtitle3} />
        </Box>
        <Box>
          <PacktextView subtitle={subtitle4} />
        </Box>
        <Box
          sx={{
            mt: 3,
            pb: 1,
            textAlign: "center",
          }}
        >
          <Typography variant="body3" color="#489C9C" fontWeight="700">
            {price}
          </Typography>
        </Box>
        <Box
          sx={{
            px: 4,
          }}
        >
          {!show && (
            <Button
              onClick={() => onCall(true)}
              // href="https://thenursingfocus.com/"
              // target={"_blank"}
              color="primary"
              sx={{
                background: "#15423F",
                width: "100%",
                py: 1,
                ":hover": {
                  background: "#15423F",
                },
              }}
            >
              <Typography sx={{ color: "white" }}>Checkout</Typography>
            </Button>
          )}
        </Box>
      </Box>
    </Card>
  );
}

function GridView({ children }) {
  return (
    <Grid item xs={12} md={12} lg={12}>
      {children}
    </Grid>
  );
}
