import { Typography, Box, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../service/firebase_init";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const OurNotes = () => {
  const navigator = useNavigate();

  const [notes, setNotes] = useState([]);

  const getNotes = async () => {
    const data = query(
      collection(db, "notes"),
      orderBy("noteHitCount", "desc"),
      where("hideNote", "==", true),
      limit(12)
    );

    const d = await getDocs(data);

    //  console.log(d?.docs?.length);

    if (d?.docs?.length) {
      const t = [];
      for (const i of d.docs) {
        if (i.data().hasData) {
          t.push(i.data());
        }
      }
      setNotes(t);
    }
  };

  useEffect(() => {
    getNotes();
  }, []);

  const responsiveCarouselConfig = {
    0: {
      items: 1
    },
    640: {
      items: 2,
      itemsFit: 'contain'
    },
    990: {
      items: 3,
      itemsFit: 'contain'
    },
    1200: {
      items: 4,
      itemsFit: 'contain'
    },
    1500: {
      items: 5,
      itemsFit: 'contain'
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#F2F2F2",
          marginInline: { xs: "1rem", md: "2rem", lg: "3rem" },
          marginTop: "2rem",
          paddingInline: { xs: "0.5rem", md: "1.5rem" },
          paddingBlock: "0.6rem",
          borderRadius: "5px",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="body1">Our Popular Notes</Typography>
        <Box
          sx={{ cursor: "pointer" }}
          component={"div"}
          onClick={() => navigator("notes")}
        >
          <Typography variant="body2">View All</Typography>
        </Box>
      </Box>
      <Box>
        <AliceCarousel container responsive={responsiveCarouselConfig} disableDotsControls={true} disableSlideInfo={true} disableButtonsControls={true} autoPlay={true} autoPlayInterval={3000} infinite={true}>
          {notes.map((v) => {
            return (
              <div className="customCardCarouselDiv" key={v.id} onClick={() => navigator("notes/" + v.id)}>
                <OurNotesView
                  images={v.poster}
                  value={v.name}
                  textContent={v.textContent}
                />
              </div>
            );
          })}
        </AliceCarousel>
      </Box>
    </Box>
  );
};

export default OurNotes;

function OurNotesView({ images, value, textContent }) {
  return (
    <Box
      sx={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        marginTop: "1.5rem",

        alignItems: "center"
        // marginInline: { xs: "1rem", md: "2rem", lg: "3rem" },
      }}
    >
      <img
        src={images}
        alt=""
        width="250px"
        height="300px"
        style={{
          margin: "0",
          padding: "0",
          boxShadow: "0px 0px 1px 0px rgb(0 0 0 / 50%)",
          borderRadius: "15px",
        }}
      />
      <Typography
        variant="body1"
        sx={{
          fontWeight: "700",
          pt: "0.5rem",
        }}
      >
        {value}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontWeight: "200",
          color: "#A5A5A5",
          pt: "0.3rem",
        }}
      >
        {textContent}
      </Typography>
    </Box>
  );
}
