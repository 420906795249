import { Box } from "@mui/material";
import React from "react";
import QuizLink from "../../Components/Quiz/QuizLink";
import QuizCrad from "../../Components/Quiz/QuizCrad";

function QuizPage() {
  return (
    <Box>
      <QuizLink />
      <QuizCrad/>
      {/* <ButtonMore /> */}
    </Box>
  );
}

export default QuizPage;
