import { Box } from "@mui/material";
import React from "react";
import VideoCourseLink from "../../Components/Video Course/VideoCourseLink"
import VideoCoursechapter from "../../Components/Video Course/VideoCoursechapter"
import ButtonMore from "../../Components/Home/ButtonMore"
function VideosPage() {
  return (
    <Box>
      <VideoCourseLink />
      <VideoCoursechapter />
      {/* <ButtonMore /> */}
    </Box>
  );
}

export default VideosPage;
