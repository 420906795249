import { Typography, Box, Grid } from "@mui/material";
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../service/firebase_init";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const Quiz = () => {
  const navigator = useNavigate();

  const [quiz, setQuiz] = useState([]);

  const getNotes = async () => {
    const data = query(collection(db, "notes"), where("hideQuiz", "==", true), orderBy("quizHitCount", "desc"), limit(8));

    const d = await getDocs(data);

    //  console.log(d?.docs?.length);

    if (d?.docs?.length) {
      const t = [];
      for (const i of d.docs) {
        if (i.data().hasQuiz) {
          t.push(i.data());
        }

      }
      setQuiz(t);
    }
  };

  useEffect(() => {
    getNotes();
  }, []);

  const responsiveCarouselConfig = {
    0: {
      items: 1
    },
    640: {
      items: 2,
      itemsFit: 'contain'
    },
    990: {
      items: 3,
      itemsFit: 'contain'
    },
    1200: {
      items: 4,
      itemsFit: 'contain'
    },
    1500: {
      items: 5,
      itemsFit: 'contain'
    }
  };

  return (
    <Box
      width="100vw"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#F2F2F2",
          marginInline: { xs: "1rem", md: "2rem", lg: "3rem" },
          marginTop: "2rem",
          paddingInline: "1.5rem",
          paddingBlock: "0.6rem",
          borderRadius: "5px",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="body1">Recent Quiz</Typography>
        <Box sx={{ cursor: "pointer", }} component={"div"} onClick={() => navigator("quiz")}><Typography variant="body2">View All</Typography></Box>
      </Box>
      <Box>
        <AliceCarousel container responsive={responsiveCarouselConfig} disableDotsControls={true} disableSlideInfo={true} disableButtonsControls={true} autoPlay={true} autoPlayInterval={3000} infinite={true}>
          {
            quiz.map((v) => {
              return <div className="customCardCarouselDiv" key={v.id} onClick={() => navigator("quiz/" + v.id)}>
                <OurNotesView images={v.poster} value={v.name} description={v.description} />
              </div>
            })
          }
        </AliceCarousel>
      </Box>
    </Box>
  );
};

export default Quiz;

function OurNotesView({ images, value, description }) {
  return (
    <Grid
      sx={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        marginTop: "1rem",
        alignItems: "center"
      }}
    >
      <img
        src={images}
        alt=""
        width="250px"
        height="300px"
        sx={{
          margin: "0",
          padding: "0",
          boxShadow: "0px 0px 1px 0px rgb(0 0 0 / 50%)",
          borderRadius: "15px",
        }}
      />
      <Typography
        variant="body1"
        sx={{
          fontWeight: "700",
          pt: "0.5rem",
        }}
      >
        {value}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontWeight: "200",
          color: "#A5A5A5",
          pt: "0.3rem",
        }}
      >
        {description}
      </Typography>
    </Grid>
  );
}
