import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const DeleteDialog = ({ open, handleClose, confirmFunction }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{"Confirm Profile Delete"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete your profile? This action is irreversible and you will lose your subscription benefits !!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    No
                </Button>
                <Button onClick={confirmFunction} color="primary" autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;