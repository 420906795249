/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Link } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, query } from "firebase/firestore";
import { db } from "../../../service/firebase_init";

const QuizPagetwoLink = () => {
  const navigator = useNavigate();

  const { qId, id } = useParams();

  const [note, setNote] = useState(null);
  const [snote, setSNote] = useState(null);

  const getData = async () => {
    const data = query(doc(db, "notes/" + id));
    const d = await getDoc(data);
    if (d.data()) {
      setNote(d.data());
    }
  };

  const getDataS = async () => {
    const data = query(doc(db, "notes/" + id + "/subChapters/", qId));
    const d = await getDoc(data);
    if (d.data()) {
      setSNote(d.data());
    }
  };

  useEffect(() => {
    getData();
    getDataS();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#F2F2F2",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#F2F2F2",
          display: "flex",
          alignItems: "center",
          height: "3.1rem",

          pl: { xs: "0.5rem", md: "4rem" },
        }}
      >
        <Link
          href="#"
          variant="body2"
          sx={{
            color: "#009D9D",
          }}
          onClick={() => navigator("/home")}
        >
          Home
        </Link>
        <Link
          href="#"
          underline="none"
          sx={{
            color: "#009D9D",
            px: "0.4rem",
          }}
        >
          /
        </Link>

        <Link
          href=""
          variant="body2"
          sx={{
            color: "#009D9D",
          }}
          onClick={() => navigator("/home/quiz")}
        >
          Quiz
        </Link>
        <Link
          href="#"
          underline="none"
          sx={{
            color: "#009D9D",
            px: "0.4rem",
          }}
        >
          /
        </Link>
        <Link
          href=""
          variant="body2"
          sx={{
            color: "#009D9D",
          }}
          onClick={() => navigator("/home/quiz/" + id)}
        >
          {note ? note.name : null}
        </Link>
        <Link
          href="#"
          underline="none"
          sx={{
            color: "#000",
            px: "0.4rem",
          }}
        >
          /
        </Link>
        <Link
          href=""
          variant="body2"
          sx={{
            color: "#000",
          }}
        >
          {snote ? snote.name : null}
        </Link>
      </Box>
    </Box>
  );
};

export default QuizPagetwoLink;
