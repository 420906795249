import { Box } from '@mui/material'
import React from 'react'
import Form from "../../Components/Home/Profile/Form";

function FormPage() {
  return (
    <Box>
        <Form/>
    </Box>
  )
}

export default FormPage