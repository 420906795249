import { Box } from "@mui/material";
import React from "react";
import QuizPagethreeLink from "../../Components/Quiz/Quiz Page three/QuizPagethreeLink";
import QuizPagethreeque from "../../Components/Quiz/Quiz Page three/QuizPagethreeque";


function QuizResult() {
  return (
    <Box>
      <QuizPagethreeLink />
      
      <QuizPagethreeque />
    </Box>
  );
}

export default QuizResult;
