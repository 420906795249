/* eslint-disable no-unused-vars */
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import plan from "./image/Plan.png";
import cards from "./image/cards.png";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc, Timestamp, where } from "firebase/firestore";
import { auth, db } from "../../../service/firebase_init";
import { useState } from "react";
import PlannerDailog from "../../Dailog/PlannerDailog";
import { dateFormatDDMMMYYYY } from "../../../service/date";
import { isUserPremium } from "../../../App";
import { Lock } from "@mui/icons-material";
import axios from "axios";
import { Stripe } from 'stripe';
import qs from "qs";
import Plan from "../../../Pages/Plan";
import { stripe_sk, cancel_url_var, success_url_var } from "../../../variables";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: "90%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D0D0D0",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#135050" : "#D0D0D0",
  },
}));
const CardPage = () => {

  const [planner, setPlanner] = useState(null);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState(null);
  const [subNote, setSubNote] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [completed, setCompleted] = useState(-1);


  const getPlannerData = async () => {
    const check = query(collection(db, "users/" + localStorage.getItem("uId") + "/planner"), where("chapterId", "==", id), where("userId", "==", localStorage.getItem("uId")));
    const get = await getDocs(check);
    //console.log(localStorage.getItem("uId"));

    if (get.docs.length) {
      setPlanner(get.docs[0].data());
      const quizCheck = query(collection(db, "users/" + localStorage.getItem("uId") + "/cardStatus"), where("notesId", "==", id), where("userId", "==", localStorage.getItem("uId")));
      const gets = await getDocs(quizCheck);
      setCompleted(gets.docs.length);
    }
  }


  const handleClose = async (v = 0, dateTime) => {
    // console.log(v);
    // console.log(dateTime);
    setOpen(false);
    if (v) {
      const data = doc(db, "users", localStorage.getItem("uId"), "planner", id);

      const check = query(collection(db, "users/" + localStorage.getItem("uId") + "/planner"), where("chapterId", "==", id), where("userId", "==", localStorage.getItem("uId")));
      const quizCheck = query(collection(db, "users/" + localStorage.getItem("uId") + "/cardStatus"), where("notesId", "==", id), where("userId", "==", localStorage.getItem("uId")));

      const get = await getDocs(check);
      if (get.docs.length) {
        for (let f of get.docs) {

          try {
            await deleteDoc(f.ref);
          } catch (e) {
            // console.log("error")
            // console.log(e)
          }
        }
      }

      const getQuiz = await getDocs(quizCheck);
      if (getQuiz.docs.length) {
        for (let f of getQuiz.docs) {

          try {
            await deleteDoc(f.ref);
          } catch (e) {
            // console.log("error")
            // console.log(e)
          }
        }
      }

      await setDoc(data, {
        chapterId: id,
        createdAt: Timestamp.now(),
        dueDate: Timestamp.fromDate(dateTime),
        type: 2,
        userId: localStorage.getItem("uId")
      });

      getPlannerData();
      // console.log(localStorage.getItem("uId"));

    }

  }

  const getData = async () => {
    setIsLoading(true);
    const data = query(doc(db, "notes", id),);
    const d = await getDoc(data);
    if (d.data()) {
      setNote(d.data());
    }
    setIsLoading(false);
    // console.log(d.data());
  };

  const getSubNote = async () => {
    const data = query(collection(db, 'notes/' + id + '/subChapters'), orderBy("index", "asc"));
    const d = await getDocs(data);
    if (d?.docs?.length) {
      const t = [];
      for (const i of d.docs) {
        //console.log(isUserPremium,"isUserPremium");
        //  console.log(i.data().index,"isUserPremium");
        // if(i.isPremium){
        //   if(isUserPremium){
        //     t.push(i.data());
        //   }
        //   continue;
        // }
        t.push(i.data());
      }
      setSubNote(t);
    }
    // console.log("length",d?.docs?.length);
  }

  useEffect(() => {
    getData();
    getSubNote();
    getPlannerData();
  }, []);

  return (
    isLoading ? <Box
      display="flex"
      justifyContent="center"
      height="60vh"
    >
      <CircularProgress />
    </Box> :
      <Box
        sx={{
          display: "flex",
          marginBlock: "3rem",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "center", md: "normal" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: { xs: "80%", sm: "60%", md: "35%", lg: "25%" },
            flexDirection: "column",
            marginRight: { xs: "0", md: "2rem" },
            backgroundColor: "rgba(21, 66, 63, 0.08);",
            borderRadius: "10px",
            paddingInline: "2rem",
            paddingBlock: "1rem",
          }}
        >

          <img
            src={note.poster}
            alt=""
            width="100%"
            height="auto"
            style={{ margin: "0", padding: "0" }}
          />
          <Typography
            variant="h4"
            sx={{
              // width: "50%",
              fontWeight: "500",
              paddingBottom: "0.8rem",
              marginTop: "20px"
            }}
          >
            {note.name}
          </Typography>

          {
            completed !== -1 ?
              <Box>
                <BorderLinearProgress variant="determinate" value={completed === 0 ? 0 : (completed / subNote.length) * 100} />
              </Box>
              : null
          }

          {
            completed !== -1 ?
              <Box
                sx={{
                  display: "flex",
                  paddingTop: "0.5rem",
                  color: "#717171",
                }}
              >
                <Typography variant="body2">{subNote.length} Card Set / </Typography>
                <Typography variant="body2" sx={{ ml: 1 }}>{completed} Completed</Typography>
              </Box>
              : null
          }

          <Box
            onClick={planner === null ? () => setOpen(true) : null}
            sx={{
              border: "1px solid #E0E0E0",
              width: { xs: "80%", sm: "60%" },
              padding: "8px",
              borderRadius: "6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "0.8rem",
              backgroundColor: "#fff",
              cursor: "pointer"
            }}
          >
            {
              planner === null ? <img src={plan} alt="" /> : null
            }

            <Typography
              variant="body2"
              sx={{
                pl: 1,
              }}
            >
              {planner === null ? "Create Study Plan" : `Due date - ${dateFormatDDMMMYYYY(planner.dueDate.toDate())}`}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            marginLeft: { xs: "0", md: "1rem" },
            marginTop: { xs: "1rem", md: "0rem" },
            height: "80vh",
            overflowY: "auto"
          }}
        >
          {
            subNote.map((v) => {
              return v.flashCardCount === 0 ? null : <CardPageViwe isPremium={v.isPremium} id={v.id} key={v.id} value={v.name} count={v.flashCardCount} subtitle={`${v.flashCardCount} Cards`} />
            })
          }

        </Box>

        <PlannerDailog open={open} handleClose={handleClose} />
      </Box>
  );
};
export default CardPage;

function CardPageViwe({ value, subtitle, id, count, isPremium }) {
  const navigator = useNavigate();
  const stripe_api = Stripe(stripe_sk);
  const [open, setOpen] = useState(false);

  async function handleCheckout(amount, packageV) {
    // console.log("isUserPremium1",isUserPremium);
    if (isUserPremium) {
      return;
    } else {
      // const stripe = await getStripe();

      // const { error } = await stripe.redirectToCheckout({
      //   lineItems: [
      //     {
      //       priceData: {
      //         currency:"usd",
      //         productData:{
      //           name:"Subscription"
      //         },
      //         unitAmount:"500"
      //       },
      //       quantity: 1,
      //     }, // Replace with your price ID and desired quantity
      //   ],

      //   mode: "payment",
      //   successUrl: `http://localhost:3000/success`,
      //   cancelUrl: `http://localhost:3000/cancel`,
      //   customerEmail: "customer@email.com",
      // });
      // console.warn(error.message);
      const priceId = await getPriceId(packageV);
      if (!priceId) {
        return null;
      }

      const d = doc(db, "users/" + localStorage.getItem("uId"));
      const u = await getDoc(d);



      const session = await stripe_api.checkout.sessions.create({
        line_items: [
          {
            price: priceId,
            quantity: 1,
          },  //Replace with your price ID and desired quantity
        ],
        mode: "subscription",
        cancel_url: cancel_url_var,
        success_url: success_url_var,
        customer_email: u.data()["email"],
      });
      // console.log( auth.currentUser.uid , "UID")
      const data = doc(db, "users", auth.currentUser.uid);
      await setDoc(data, {
        isSubscribe: false,
        subscribetionpackge: packageV,
        session_id: session.id
      }, { merge: true });
      window.open(session.url, "_self");
    }
    setOpen(false);
  }

  const getPriceId = async (pname) => {
    let name = "";

    if (pname === "com.nursingfocus.yearly") {
      name = "NR-Yearly";
    } else if (pname === "com.nursingfocus.six_month") {
      name = "NR-Six Month";
    } else if (pname === "com.nursingfocus.monthly") {
      name = "NR-Monthly";
    }
    const { data } = await axios.get("https://api.stripe.com//v1/products", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer "+stripe_sk,
      },
    });

    // console.log(data,"dataa")

    if (data && data.data) {
      const product = data.data.filter((v) => v.name === name);
      if (product && product.length) {
        const { data: dataPrice } = await axios.get(
          "https://api.stripe.com//v1/prices",
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Bearer "+stripe_sk,
            },
          }
        );
        // console.log(dataPrice,"dataPrice")
        const price = dataPrice.data.filter((v) => v.product === product[0].id);
        if (price && price.length) {
          // console.log("id",price[0].id);
          return price[0].id;
        }
        return null;
      }
    }

    return null;
  };


  return (
    <>
      <Box
        sx={{
          marginBottom: "0.8rem",
          cursor: "pointer",

        }}
        onClick={
          () => {
            if (isPremium && isUserPremium) {
              if (count) {
                navigator(id)
                // window.open(link);
              }
            } else {
              if (isPremium) {
                //  
                setOpen(true);
              } else {
                if (count) {
                  navigator(id)
                  // window.open(link);
                }
              }
            }
          }
        }
      >
        <Box
          sx={{
            backgroundColor: "#F7F7F7",
            width: { xs: "280px", sm: "450px" },
            display: "flex",
            justifyContent: "space-between",
            paddingInline: "2rem",
            paddingBlock: "1rem",
            borderRadius: "9px",
            "&:hover": {
              backgroundColor: "#D2E3E3",
            },
          }}
        >
          <Box>
            <Typography
              variant="body1"
              sx={{
                paddingBottom: "0.1rem",
              }}
            >
              {value}
            </Typography>
            <Typography variant="body2" sx={{
              color: "#565656;"
            }}>{subtitle}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {
              isPremium && !isUserPremium ? <Lock sx={{ color: "#5a5555" }} /> : <img src={cards} alt="" style={{}} />
            }

          </Box>
        </Box>
      </Box>
      {open && (
        <Plan
          handleOnClose={() => {

            setOpen(false);
          }}
          open={open}
          onCall={(v, packageV) => {
            handleCheckout(v, packageV);
          }}
        />
      )}
    </>
  );
}
