// Import the functions you need from the SDKs you need
import { initializeApp, } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth,signInWithCredential,signInWithPopup } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfigs = {
  "app.nclex.focuslearninghub.com": {
      apiKey: process.env.REACT_APP_NURSING_API_KEY,
      authDomain: process.env.REACT_APP_NURSING_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_NURSING_PROJECT_ID,
      storageBucket: process.env.REACT_APP_NURSING_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_NURSING_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_NURSING_APP_ID,
      measurementId: process.env.REACT_APP_NURSING_MEASUREMENT_ID
  },
  "app.med.focuslearninghub.com": {
      apiKey: process.env.REACT_APP_MED_API_KEY,
      authDomain: process.env.REACT_APP_MED_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_MED_PROJECT_ID,
      storageBucket: process.env.REACT_APP_MED_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MED_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_MED_APP_ID,
      measurementId: process.env.REACT_APP_MED_MEASUREMENT_ID
  },
  "app.vet.focuslearninghub.com": {
      apiKey: process.env.REACT_APP_VET_API_KEY,
      authDomain: process.env.REACT_APP_VET_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_VET_PROJECT_ID,
      storageBucket: process.env.REACT_APP_VET_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_VET_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_VET_APP_ID,
      measurementId: process.env.REACT_APP_VET_MEASUREMENT_ID
  },
  "app.pmp.focuslearninghub.com": {
      apiKey: process.env.REACT_APP_PMP_API_KEY,
      authDomain: process.env.REACT_APP_PMP_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_PMP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_PMP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_PMP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_PMP_APP_ID,
      measurementId: process.env.REACT_APP_PMP_MEASUREMENT_ID
  },
  "app.gre.focuslearninghub.com": {
      apiKey: process.env.REACT_APP_GRE_API_KEY,
      authDomain: process.env.REACT_APP_GRE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_GRE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_GRE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_GRE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_GRE_APP_ID,
      measurementId: process.env.REACT_APP_GRE_MEASUREMENT_ID
  },
  "app.re.focuslearninghub.com": {
      apiKey: process.env.REACT_APP_RE_API_KEY,
      authDomain: process.env.REACT_APP_RE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_RE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_RE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_RE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_RE_APP_ID,
      measurementId: process.env.REACT_APP_RE_MEASUREMENT_ID
  },
  "app.toefl.focuslearninghub.com": {
      apiKey: process.env.REACT_APP_TOEFL_API_KEY,
      authDomain: process.env.REACT_APP_TOEFL_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_TOEFL_PROJECT_ID,
      storageBucket: process.env.REACT_APP_TOEFL_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_TOEFL_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_TOEFL_APP_ID,
      measurementId: process.env.REACT_APP_TOEFL_MEASUREMENT_ID
  },
  "app.ielts.focuslearninghub.com": {
      apiKey: process.env.REACT_APP_IELTS_API_KEY,
      authDomain: process.env.REACT_APP_IELTS_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_IELTS_PROJECT_ID,
      storageBucket: process.env.REACT_APP_IELTS_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_IELTS_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_IELTS_APP_ID,
      measurementId: process.env.REACT_APP_IELTS_MEASUREMENT_ID
  },
  "app.dental.focuslearninghub.com": {
      apiKey: process.env.REACT_APP_DENTAL_API_KEY,
      authDomain: process.env.REACT_APP_DENTAL_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_DENTAL_PROJECT_ID,
      storageBucket: process.env.REACT_APP_DENTAL_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_DENTAL_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_DENTAL_APP_ID,
      measurementId: process.env.REACT_APP_DENTAL_MEASUREMENT_ID
  },
  "app.nclexpn.focuslearninghub.com": {
      apiKey: process.env.REACT_APP_NCLEXPN_API_KEY,
      authDomain: process.env.REACT_APP_NCLEXPN_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_NCLEXPN_PROJECT_ID,
      storageBucket: process.env.REACT_APP_NCLEXPN_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_NCLEXPN_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_NCLEXPN_APP_ID,
      measurementId: process.env.REACT_APP_NCLEXPN_MEASUREMENT_ID
  },
  "app.asvab.focuslearninghub.com": {
      apiKey: process.env.REACT_APP_ASVAB_API_KEY,
      authDomain: process.env.REACT_APP_ASVAB_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_ASVAB_PROJECT_ID,
      storageBucket: process.env.REACT_APP_ASVAB_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_ASVAB_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_ASVAB_APP_ID,
      measurementId: process.env.REACT_APP_ASVAB_MEASUREMENT_ID
  },
  "app.atiteas.focuslearninghub.com": {
      apiKey: process.env.REACT_APP_ATITEAS_API_KEY,
      authDomain: process.env.REACT_APP_ATITEAS_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_ATITEAS_PROJECT_ID,
      storageBucket: process.env.REACT_APP_ATITEAS_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_ATITEAS_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_ATITEAS_APP_ID,
      measurementId: process.env.REACT_APP_ATITEAS_MEASUREMENT_ID
  },
  "app.sat.focuslearninghub.com": {
      apiKey: process.env.REACT_APP_SAT_API_KEY,
      authDomain: process.env.REACT_APP_SAT_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_SAT_PROJECT_ID,
      storageBucket: process.env.REACT_APP_SAT_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_SAT_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_SAT_APP_ID,
      measurementId: process.env.REACT_APP_SAT_MEASUREMENT_ID
  },
  "app.cna.focuslearninghub.com": {
      apiKey: process.env.REACT_APP_CNA_API_KEY,
      authDomain: process.env.REACT_APP_CNA_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_CNA_PROJECT_ID,
      storageBucket: process.env.REACT_APP_CNA_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_CNA_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_CNA_APP_ID,
      measurementId: process.env.REACT_APP_CNA_MEASUREMENT_ID
  },
  "localhost": {
      apiKey: process.env.REACT_APP_DENTAL_API_KEY,
      authDomain: process.env.REACT_APP_DENTAL_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_DENTAL_PROJECT_ID,
      storageBucket: process.env.REACT_APP_DENTAL_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_DENTAL_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_DENTAL_APP_ID,
      measurementId: process.env.REACT_APP_DENTAL_MEASUREMENT_ID
  }
  // Add more configurations as needed
};

// Get the current domain
const currentDomain = window.location.hostname;

// Select the appropriate Firebase configuration
const firebaseConfig = firebaseConfigs[currentDomain];

export  const signInWithCredentialC = signInWithCredential;
export  const signInWithPopupC = signInWithPopup;
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const storage = getStorage(app);

export const db = getFirestore(app);



