import { Box } from "@mui/material";
import React from "react";

import VideoLink from "../../Components/Video Course/VideoLink"
import Video from "../../Components/Video Course/Videos/Video"

function VideosDetails() {
  return (
    <Box>
      <VideoLink />
      <Video />
    </Box>
  );
}

export default VideosDetails;
