 /* eslint-disable no-unused-vars */
import { Typography, Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import video1 from './image/video.png'
import video2 from './image/video2.png'
import video3 from './image/video3.png'

const VideoCoursechapter = () => {
  const navigator = useNavigate();
  return (
    <Box>
      
      <Box
      sx={{
        marginBlock:"1rem"
      }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={4} component={"div"} onClick={()=>navigator("1")}>
            <OurNotesView images={video1} value="Med Surgical Bundle" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} component={"div"} onClick={()=>navigator("1")}>
            <OurNotesView images={video2} value="Brain Disorder" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} component={"div"} onClick={()=>navigator("1")}>
            <OurNotesView images={video3} value="Pharmacology" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} component={"div"} onClick={()=>navigator("1")}>
            <OurNotesView images={video1} value="Med Surgical Bundle" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} component={"div"} onClick={()=>navigator("1")}>
            <OurNotesView images={video2} value="Brain Disorder" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} component={"div"} onClick={()=>navigator("1")}>
            <OurNotesView images={video3} value="Pharmacology" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} component={"div"} onClick={()=>navigator("1")}>
            <OurNotesView images={video1} value="Med Surgical Bundle" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} component={"div"} onClick={()=>navigator("1")}>
            <OurNotesView images={video2} value="Brain Disorder" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} component={"div"} onClick={()=>navigator("1")}>
            <OurNotesView images={video3} value="Pharmacology" />
          </Grid>
        </Grid>
      </Box>
      
      
    </Box>
  );
};

export default VideoCoursechapter;

function OurNotesView({ images,value}) {
  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        marginTop: "1rem",
       marginInline: {xs:"1rem",md:"2rem",lg:"3rem"},
      }}
    >
      <img
        src={images}
        alt=""
        width="100%"
        height="auto"
        sx={{
          margin: "0",
          padding: "0",
          boxShadow: "0px 0px 1px 0px rgb(0 0 0 / 50%)",
          borderRadius: "15px",
        }}
      />
      <Typography variant="body1" 
      sx={{
        paddingTop:"0.5rem",
        fontWeight:"600"
      }}
      
      >{value}</Typography>
    </Grid>
  );
}
