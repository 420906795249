import { Box } from '@mui/material'
import React from 'react'
import QuizPagefourLink from "../../Components/Quiz/Quiz Page four/QuizPagefourLink";
import QuizPagefour from "../../Components/Quiz/Quiz Page four/QuizPagefour";

function QuizCreate() {
  return (
    <Box>
        <QuizPagefourLink/>
          <QuizPagefour/>
    </Box>
  )
}

export default QuizCreate